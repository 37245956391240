import React, { useState } from "react";
import { APPNAME, FORM_STEPS } from "../../../Utils/Constants";
import { useTranslation } from "react-i18next";
import { recoverUserService } from "../../../services/services";

const RecoverFormBackend = ({ tenantLanguages, changeFormStep }) => {
  const { t, i18n } = useTranslation();

  //const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [mailSent, setMailSent] = useState(false);

  const languagesName = {
    es: t("lang.es"),
    gl: t("lang.gl"),
    eu: t("lang.eu"),
    en: t("lang.en"),
    ca: t("lang.ca"),
    vc: t("lang.vc"),
    pt: t("lang.pt"),
    fr: t("lang.fr"),
  };

  const handleSubmit = (event) => {
    //const username = email.split('@')[0];
    recoverUserService(APPNAME, username, {}, requestRecoverCallback, requestRecoverErrorCallback);
    event.preventDefault();
  };

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const requestRecoverCallback = (data) => {
    setMailSent(true);
  };

  const requestRecoverErrorCallback = (error) => {
    console.log("Error recuperando correo");
  }

  return (
    <form onSubmit={handleSubmit}>
      <h1>{t("user.recover.title")}</h1>

      <div className="LanguageSelector">
        <select value={i18n.language} onChange={handleLanguageChange}>
          {tenantLanguages.map((lang) => (
            <option key={lang} value={lang}>
              {languagesName[lang]}
            </option>
          ))}
        </select>
      </div>
      {mailSent && (
        <div className="SuccessBlock">
          <h6>{t("user.recover.success")}</h6>
        </div>
      )}
      {!mailSent && <label className="UsernameBlock">
        <input
          className="LoginInput"
          type="text"
          placeholder={t("user.recover.username")}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </label>
      }

      <div className="ButtonGroup">
        {!mailSent && <button type="submit" className="LoginFormButton">
          {t('user.login.send')}
        </button>}
        <button type="button" className="RegisterButton" onClick={() => changeFormStep(FORM_STEPS.LOGIN)}>
          {t('user.login.back')}
        </button>
      </div>
    </form>
  );
};

export default RecoverFormBackend;
