import React, {useState, useEffect} from "react";
import './ShareContentModalComponent.css';
import HandleClickOutside from "../../../../app/src/Components/DOM/Click/HandleClickOutside";
import {useTranslation} from 'react-i18next';
import {
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
} from "react-share";
import ThreadsIcon from "../../../../app/src/Components/common/ThreadsIcon";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {
    SOCIAL_ICON_SIZE_DESKTOP, SOCIAL_ICON_SIZE_MOBILE,
    SOCIAL_NETWORK_FACEBOOK, SOCIAL_NETWORK_TELEGRAM,
    SOCIAL_NETWORK_TWITTER,
    SOCIAL_NETWORK_WHATSAPP,
    SOCIAL_NETWORK_LINKEDIN,
    SOCIAL_NETWORK_THREADS,
    URL_COPIED_TIMEOUT
} from "./ShareContentModalConstants";
import {SWITCHER_WIDTH} from "../../../../app/src/Utils/Constants";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../app/src/Resources/Multitenant/tenantConstants";


const ShareContentModalComponent = ({refShare, hideShareModal, title, description}) => {
    const {t} = useTranslation();
    const prefix = MULTITENANT_CONSTANTS[TENANT].domain;
    const href = window.location.href;
    const suffix = href.match(/\/(videodetail|player)\/\d+/)[0].substring(1);
    const url = prefix + suffix;
    const [URLCopied, setURLCopied] = useState(null);

    const quote = title + '\n\n' + description;

    const handleCopyUrl = () => {
        setURLCopied(!URLCopied);
    };

    useEffect(() => {
        if (URLCopied) {
            setTimeout(() => handleCopyUrl(), URL_COPIED_TIMEOUT)
        }
    }, [URLCopied, handleCopyUrl])

    const mobileVersion = window.innerWidth <= SWITCHER_WIDTH;
    const iconSize = mobileVersion ? SOCIAL_ICON_SIZE_MOBILE : SOCIAL_ICON_SIZE_DESKTOP;

    return (
        <div className="ModalBackground">
            <HandleClickOutside elementref={refShare} callback={hideShareModal}/>
            <div className="ShareModal" ref={refShare}>
                <p className="ShareTitle">{t('share')}</p>
                <div className="SocialContainer">
                    <div className="SocialRow">
                        <div onClick={() => window.plugins.socialsharing.shareViaFacebook(quote, null, url, null, function(){alert(t('share.error'))})}>
                            <FacebookIcon size={iconSize} round className="SocialIcon" />
                            <p className="SocialName">{SOCIAL_NETWORK_FACEBOOK}</p>
                        </div>
                        <div onClick={() => window.plugins.socialsharing.shareViaTwitter(quote, null, url, null, function(){alert(t('share.error'))})}>
                            <TwitterIcon size={iconSize} round className="SocialIcon" />
                            <p className="SocialName">{SOCIAL_NETWORK_TWITTER}</p>
                        </div>
                        <div onClick={() => window.plugins.socialsharing.shareViaWhatsApp(quote, null, url, null, function(){alert(t('share.error'))})}>
                            <WhatsappIcon size={iconSize} round className="SocialIcon" />
                            <p className="SocialName">{SOCIAL_NETWORK_WHATSAPP}</p>
                        </div>
                    </div>
                    <div className="SocialRow">
                        <div onClick={() => window.plugins.socialsharing.shareVia('org.telegram.messenger', quote, null, null, url, null, function(){alert(t('share.error'))})}>
                            <TelegramIcon size={iconSize} round className="SocialIcon" />
                            <p className="SocialName">{SOCIAL_NETWORK_TELEGRAM}</p>
                        </div>
                        <div onClick={() => window.plugins.socialsharing.shareVia('com.linkedin.android', quote, null, null, url, null, function(){alert(t('share.error'))})}>
                            <LinkedinIcon size={iconSize} round className="SocialIcon" />
                            <p className="SocialName">{SOCIAL_NETWORK_LINKEDIN}</p>
                        </div>
                        <div onClick={() => window.plugins.socialsharing.shareVia('com.instagram.threads', quote, null, null, url, null, function(){alert(t('share.error'))})}>
                            <ThreadsIcon size={iconSize} round className="SocialIcon" />
                            <p className="SocialName">{SOCIAL_NETWORK_THREADS}</p>
                        </div>
                    </div>
                </div>
                {url && <div className="URLContainer">
                    <p className={mobileVersion ? "URLTextMobile" : "URLText"}>{url}</p>
                    <CopyToClipboard text={url} onCopy={() => handleCopyUrl()}>
                        <button
                            className={URLCopied ? "CopiedButton" : "CopyButton"}>{URLCopied ? t('copied.url') : t('copy.url')}</button>
                    </CopyToClipboard>
                </div>}
                <div className="ButtonContainer">
                    <button onClick={() => hideShareModal()}
                            className="ReportOptionButton button-focused CancelButton">{t('global.button.cancel')}</button>
                </div>
            </div>
        </div>
    )
};

export default ShareContentModalComponent;