export const URL_COPIED_TIMEOUT = 2000;

export const SOCIAL_NETWORK_FACEBOOK = 'Facebook';
export const SOCIAL_NETWORK_TWITTER = 'Twitter';
export const SOCIAL_NETWORK_WHATSAPP = 'WhatsApp';
export const SOCIAL_NETWORK_TELEGRAM = 'Telegram';
export const SOCIAL_NETWORK_LINKEDIN = 'LinkedIn';
export const SOCIAL_NETWORK_THREADS = 'Threads';

export const SOCIAL_ICON_SIZE_DESKTOP = 60;
export const SOCIAL_ICON_SIZE_MOBILE = 30;
