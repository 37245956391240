import Keycloak from "keycloak-js";
import {KC_BASE_URI, KC_CLIENT_ID, KC_REALM} from "../Resources/Multitenant/tenantConstants";
import { isCordovaAvailable, removeTokensFromSecureStorage } from "./secureStorageUtil";
import { removeTokenFromCookie } from "./utils";
import { URL_HOME } from "../Components/Routes/Location";


const keycloakConfig = {
  realm: KC_REALM,
  url: KC_BASE_URI,
  clientId: KC_CLIENT_ID,
  adapter: 'cordova-native',
};

const keycloak = new Keycloak(keycloakConfig);

export const initializeKeycloak = (token, refreshToken, username) => {
  keycloak.token = token;
  keycloak.refreshToken = refreshToken;
  keycloak.tokenParsed = {preferred_username: username}
  keycloak.initialized = true;
  keycloak.authenticated = true;
  keycloak.logout = () => {
    keycloak.authenticated = false;
    keycloak.token = null;
    keycloak.refreshToken = null;
    keycloak.tokenParsed = null;
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("username");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("username");
    try {
      removeTokensFromSecureStorage();
    } catch (e) {
      console.error("Error removing tokens from secure storage:", e);
    }
  }
}

export const logoutFromKeycloak = async () => {
  if (isCordovaAvailable()) {
    keycloak.logout();
  } else {
    removeTokenFromCookie();
    await keycloak?.logout({ redirectUri: window.location.origin + URL_HOME});
  }
}

export const hasRole = (role) => (!!keycloak ? keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role) : false);

export const hasRoles = (roles) =>
  !!keycloak ? roles.some((r) => keycloak.hasRealmRole(r) || keycloak.hasResourceRole(r)) : false;
export default keycloak;
