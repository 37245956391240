import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";

import { ROL_KID, ROL_TEEN, ROL_ADULT } from "./PantallasRexistro";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InfoPanel } from "@components/GUI/InfoPanel/InfoPanel";
import AlertNotification from "@components/GUI/Alert/Alert";
import RegisterNavigationComponent from "../common/components/RegisterNavigationComponent";
import { SIGNUP_MODE } from "../../../../app/src/Resources/Multitenant/tenantConstants";
import { SIGNUP_MODE_SIMPLE } from "./RegisterConstant";
import { invalidCharacter } from "./NameUser";
import { handleShowPassword } from "../../../../app/src/Utils/utils";

let timeoutU = null;
let timeoutE = null;

function DataUser(props) {
  const { invalidUsername, invalidPassword, invalidEmail } = props;

  const [showPassword, setShowPassword] = useState([false, false]);
  const [nome, setNome] = useState("");
  const [apelido, setApelido] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [currentpassword, setCurrentpassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [birthdate, setBirthdate] = useState(null);
  const [invalidPassword2, setInvalidPassword2] = useState(false);
  const [invalidFields, setInvalidFields] = useState(false);
  const [UsernameInvalid, setUsernameInvalid] = useState(props.invalidUsername);
  const refMsgError = useRef(null);
  const [followButtonDisabled, setFollowButtonDisabled] = useState(
    UsernameInvalid || invalidPassword || invalidEmail
  );

  const { t } = useTranslation();
  const { updateUserData, gotoValidateToUpdateUserData, setDatosState } = props;

  useEffect(() => {
    setFollowButtonDisabled(
      UsernameInvalid || invalidPassword || invalidEmail || invalidUsername
    );
  }, [invalidUsername, invalidPassword, invalidEmail]);

  //Inputs
  const handleChangeNome = (e) => {
    let n = e.target.value;
    setNome(n);
    setInvalidFields(false);
    props.ctrlSetFirstName(n);
  };

  const handleChangeApelido = (e) => {
    let n = e.target.value;
    setApelido(n);
    setInvalidFields(false);
    props.ctrlSetLastName(n);
  };

  const handleChangeUsername = (e) => {
    if (timeoutU) {
      clearTimeout(timeoutU);
    }
    const withSpace = /\s/;
    const u = e.target.value;

    if (u.toUpperCase().includes(invalidCharacter) || withSpace.test(u)) {
      setUsernameInvalid(true);
    } else {
      timeoutU = setTimeout(() => {
        props.ctrlSetUsername(u);
      }, 1500);
      setUsernameInvalid(false);
      setInvalidFields(false);
    }

    setUsername(u);
  };

  const handleChangeEmail = (e) => {
    const email = e.target.value;

    if (timeoutE) {
      clearTimeout(timeoutE);
    }

    setEmail(email);
    setInvalidFields(false);

    timeoutE = setTimeout(() => {
      props.ctrlSetEmail(email);
    }, 2500);
  };

  const handleChangeCurrentPassword = (e) => {
    let u = e.target.value;
    setCurrentpassword(u);
  };

  const handleChangePassword = (e) => {
    let u = e.target.value;
    setPassword(u);
    if (u === password2) {
      setInvalidPassword2(false);
    }
  };

  const handleChangePassword2 = (e) => {
    let u = e.target.value;
    setPassword2(u);
  };

  const handleFinishPassword2 = (e) => {
    setInvalidFields(false);
    setInvalidPassword2(false);
    if (password !== password2) {
      setInvalidPassword2(true);
    } else if (password2 !== null && password2 !== "") {
      props.ctrlSetCredentials(password2);
    }
  };

  const handleChangeBirthdate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    props.ctrlSetBirthdate(formattedDate);
    setBirthdate(date);
    setInvalidFields(false);
  };

  //Validacion
  const validar = () => {
    if (
      !nome ||
      !apelido ||
      !email ||
      !birthdate ||
      (props?.datos?.rol === ROL_ADULT && !username) ||
      (props?.datos?.rol === ROL_ADULT && !password) ||
      (props?.datos?.rol === ROL_ADULT && !password2) ||
      (props?.datos?.rol === ROL_ADULT && password !== password2)
    ) {
      setInvalidFields(true);
      return;
    } else {
      setInvalidFields(false);
    }
    //Contrasinal
    if (password === password2) {
      setInvalidPassword2(false);
    } else {
      setInvalidPassword2(true);
      return;
    }

    props.validar(password2);
  };

  useEffect(() => {
    if (gotoValidateToUpdateUserData) {
      validar();
    }
  }, [gotoValidateToUpdateUserData]);

  return (
    <div className="DataUser">
      <div className="WizardContainer">
        <div className="Descricion">
          {(props?.datos?.rol === ROL_KID ||
            props?.datos?.rol === ROL_TEEN) && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("register.titulodatauserkid"),
                }}
              ></div>
              <div
                className={"subTitle"}
                dangerouslySetInnerHTML={{
                  __html: t("register.textodatauserkid"),
                }}
              ></div>
            </>
          )}
          {(props?.datos?.rol === ROL_ADULT ||
            SIGNUP_MODE === SIGNUP_MODE_SIMPLE) && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("register.titulodatauserteen"),
                }}
              ></div>
            </>
          )}
        </div>
        {(props?.datos?.rol === ROL_KID || props?.datos?.rol === ROL_TEEN) && (
          <></>
        )}
        <Row className={"formFirstLine"}>
          <Col xs="12" md={6}>
            <label className="campoForm campoFirstName">
              <input
                className="InputNome"
                type="text"
                placeholder={t("register.datanome")}
                value={nome}
                onChange={handleChangeNome}
              />
            </label>
          </Col>
          <Col xs="12" md={6}>
            <label className="campoForm campoLastName">
              <input
                className="InputApelido"
                type="text"
                placeholder={t("register.dataapelido")}
                value={apelido}
                onChange={handleChangeApelido}
              />
            </label>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md={6}>
            <div className="campoForm campoBirthdate">
              <DatePicker
                selected={birthdate}
                onChange={handleChangeBirthdate}
                placeholderText={t("register.databirthdate")}
                dateFormat="dd/MM/yyyy"
                className="InputBirthdate datepicker-dropdown"
                propperPlacement="bottom-start"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md={6}></Col>
          <Col xs="12" md={6}></Col>
        </Row>
        <Row>
          <Col xs="12" md={6}>
            <label className="campoForm campoEmail">
              <input
                className="InputEmail"
                type="text"
                placeholder={t("register.dataemail")}
                value={email}
                onChange={handleChangeEmail}
              />
            </label>
          </Col>
        </Row>

        {(props?.datos?.rol === ROL_ADULT ||
          SIGNUP_MODE === SIGNUP_MODE_SIMPLE) && (
          <Row>
            <Col xs="12" md={6}>
              <label className="campoForm campoUsername">
                <input
                  className="InputUsuario"
                  type="text"
                  placeholder={t("register.datausername")}
                  value={username}
                  onChange={handleChangeUsername}
                />
              </label>
            </Col>
            <Col xs="12" md={6}>
              <label className="campoForm currentPassword">
                <h2>{t("register.updatepassword")}</h2>
                <input
                  className="InputPassword"
                  type={showPassword[0] ? "text" : "password"}
                  placeholder={t("register.currentpassword")}
                  value={currentpassword}
                  onChange={handleChangeCurrentPassword}
                />
                <div
                  className={"iconInput"}
                  onClick={() =>
                    setShowPassword(handleShowPassword(0, showPassword))
                  }
                >
                  <ion-icon
                    name={showPassword[0] ? "eye-outline" : "eye-off-outline"}
                  />
                </div>
              </label>
            </Col>
          </Row>
        )}
        <InfoPanel
          classButton={"passwordInfo"}
          classPanel={"Enumeracion"}
          icon={"fa fa-info-circle"}
          hideonclick={true}
          displayonvoer={true}
        >
          <span className={"Linea"}>{t("register.infopassword1")}</span>
          <span className={"Linea"}>{t("register.infopassword2")}</span>
          <span className={"Linea"}>{t("register.infopassword3")}</span>
          <span className={"Sublinea"}>{t("register.infopassword4")}</span>
        </InfoPanel>
        <Row>
          <Col xs="12" md="6">
            <label className="campoForm campoPassword">
              <input
                className="InputPassword"
                type={showPassword[0] ? "text" : "password"}
                placeholder={t("register.datapassword")}
                value={password}
                onChange={handleChangePassword}
              />
              <div
                className={"iconInput"}
                onClick={() =>
                  setShowPassword(handleShowPassword(0, showPassword))
                }
              >
                <ion-icon
                  name={showPassword[0] ? "eye-outline" : "eye-off-outline"}
                />
              </div>
            </label>
          </Col>
          <Col xs="12" md="6">
            <label className="campoForm campoPassword2">
              <input
                className="InputPassword"
                type={showPassword[1] ? "text" : "password"}
                placeholder={t("register.datapassword2")}
                value={password2}
                onChange={handleChangePassword2}
                onBlur={handleFinishPassword2}
              />
              <div
                className={"iconInput"}
                onClick={() =>
                  setShowPassword(handleShowPassword(1, showPassword))
                }
              >
                <ion-icon
                  name={showPassword[1] ? "eye-outline" : "eye-off-outline"}
                />
              </div>
            </label>
          </Col>
        </Row>

        <div ref={refMsgError}>
          {invalidPassword2 && (
            <AlertNotification
              type="error"
              showtitle={false}
              text={t("register.invalidpassword2")}
            />
          )}
          {(UsernameInvalid || invalidUsername) && (
            <AlertNotification
              type="error"
              showtitle={false}
              text={t("register.invalidusername")}
            />
          )}
          {props.invalidEmail && (
            <AlertNotification
              type="error"
              showtitle={false}
              text={t("register.invalidemail")}
            />
          )}
          {props.invalidPassword && (
            <AlertNotification
              type="error"
              showtitle={false}
              text={t("register.invalidpassword")}
            />
          )}
          {invalidFields && (
            <AlertNotification
              type="error"
              showtitle={false}
              text={t("register.invalidfields")}
            />
          )}
        </div>
      </div>
      {!updateUserData && (
        <RegisterNavigationComponent
          handlerBeforePage={props.anteriorPantalla}
          handlerNextPage={validar}
          followButtonDisabled={
            followButtonDisabled || invalidPassword2 || UsernameInvalid
          }
        />
      )}
    </div>
  );
}

export default DataUser;
