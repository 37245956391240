let secureStorageInstance = null;
let cordovaAvailable = false;

const MAX_ATTEMPTS = 5;
let attemptCount = 0;

export const isCordovaAvailable = () => {
    return !!window.cordova;
};

export const isSecureStorageInstantiated = () => {
    return cordovaAvailable && secureStorageInstance;
}

// Inicializa SecureStorage en dispositivos móviles
export const initSecureStorage = (onSuccess, onError) => {
    if (window.cordova && window.cordova.plugins && window.cordova.plugins.SecureStorage) {
        const storage = new window.cordova.plugins.SecureStorage(
            () => {
                console.log("SecureStorage initialized correctamente");
                cordovaAvailable = true;
                secureStorageInstance = storage;
                if (onSuccess) onSuccess();
            },
            (error) => {
                console.error("Error al inicializar SecureStorage:", error);
                if (error === "Device is not secure") {
                    alert("Por favor, asegúrate de que el dispositivo tenga un método de bloqueo seguro activado (PIN, patrón, huella digital, etc.).");
                }
                if (onError) onError(error);
            },
            "my_secure_storage"
        );
    } else if (attemptCount < MAX_ATTEMPTS) {
        attemptCount++;
        console.warn(`Intentando inicializar Cordova, intento ${attemptCount}`);
        setTimeout(() => initSecureStorage(onSuccess, onError), 1000); // Reintento cada segundo
    } else {
        console.error("Cordova o SecureStorage no se pudo inicializar después de múltiples intentos");
        if (onError) onError("Cordova no disponible o no se pudo inicializar");
    }
};

// Almacena tokens de forma segura
export const setTokensInSecureStorage = (token, refreshToken, username) => {
    return new Promise((resolve, reject) => {
        if (cordovaAvailable && secureStorageInstance) {
            secureStorageInstance.set(
                () => {
                    secureStorageInstance.set(
                        () => {
                            secureStorageInstance.set(
                                () => {
                                    resolve();
                                },
                                (error) => {
                                    console.error("Error storing username:", error);
                                    reject(error);
                                },
                                "username",
                                username
                            );
                        },
                        (error) => {
                            console.error("Error storing refresh token:", error);
                            reject(error);
                        },
                        "refresh_token",
                        refreshToken
                    );
                },
                (error) => {
                    console.error("Error storing access token:", error);
                    reject(error);
                },
                "access_token",
                token
            );
        } else {
            reject("SecureStorage no está disponible.");
        }
    });
};

// Recupera tokens de forma segura
export const getTokensFromSecureStorage = () => {
    return new Promise((resolve, reject) => {
        if (cordovaAvailable && secureStorageInstance) {
            secureStorageInstance.get(
                (token) => {
                    secureStorageInstance.get(
                        (refreshToken) => {
                            secureStorageInstance.get(
                                (username) => {
                                    resolve({ token, refreshToken, username });
                                },
                                (error) => {
                                    console.error("Error retrieving username:", error);
                                    reject(error);
                                },
                                "username"
                            );
                        },
                        (error) => {
                            console.error("Error retrieving refresh token:", error);
                            reject(error);
                        },
                        "refresh_token"
                    );
                },
                (error) => {
                    console.error("Error retrieving access token:", error);
                    reject(error);
                },
                "access_token"
            );
        } else {
            reject("SecureStorage no está disponible.");
        }
    });
};

// Elimina tokens de forma segura
export const removeTokensFromSecureStorage = () => {
    return new Promise((resolve, reject) => {
        if (cordovaAvailable && secureStorageInstance) {
            secureStorageInstance.remove(
                () => {
                    console.log("Access token removed successfully");
                    secureStorageInstance.remove(
                        () => {
                            console.log("Refresh token removed successfully");
                            secureStorageInstance.remove(
                                () => {
                                    console.log("Username removed successfully");
                                    resolve();
                                },
                                (error) => {
                                    console.error("Error removing username:", error);
                                    reject(error);
                                },
                                "username"
                            );
                        },
                        (error) => {
                            console.error("Error removing refresh token:", error);
                            reject(error);
                        },
                        "refresh_token"
                    );
                },
                (error) => {
                    console.error("Error removing access token:", error);
                    reject(error);
                },
                "access_token"
            );
        } else {
            reject("SecureStorage no está disponible.");
        }
    });
};
