import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

import "./HomeComponent.css";
import {
  displayOnBoardingActionCreator,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import { widgetTypesWithoutSeparateSpace } from "../../../../app/src/Utils/Constants";
import Footer from "../../../../app/src/Components/GUI/Footer/Footer";
import { DEFAULT_SLIDE_ROTATION_INTERVAL } from "../../../../detail/src/Pages/DetailPage/PlaylistConstants";
import MenuApiComponent from "../../../../app/src/Pages/Menu/MenuApiComponent";
import {
  logUserAnalyticService,
  getMainScreenService,
  getScreenService,
} from "../../../../app/src/services/services";
import {
  HOME_VALUE,
  PAGE_ID_KEY,
  PAGE_VIEW_ACTION,
} from "../../../../app/src/analyticsConstants/analyticsConstants";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import Playlist from "../../../../detail/src/Pages/DetailPage/Playlist";
import AlertNotification from "../../../../app/src/Components/GUI/Alert/Alert";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import playlist from "../../../../detail/src/Pages/DetailPage/Playlist";
import {USER_ANONYMOUS} from "./HomeComponentConstants";
import {getToken, getWidgetTitle} from "../../../../app/src/Utils/utils";
import jwtDecode from "jwt-decode";
import { isCordovaAvailable } from "../../../../app/src/Utils/secureStorageUtil";
import ExpiredSessionModalComponent from "../../../../app/src/Components/common/ExpiredSessionModalComponent";
import ErrorMessageModalComponent from "../../../../app/src/Components/common/ErrorMessageModalComponent";
import NoContentComponent from "../../../../catalogue/src/Pages/Catalogo/components/NoContentComponent";

const HomeComponent = (props) => {
  const dispatch = useDispatch();
  const {scrollRef, isGuest = false} = props;
  const { userData } = useSelector((state) => state.commonState);
  const { keycloak, initialized } = useKeycloak();
  const { screenId } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isGuestFromParams = JSON.parse(searchParams.get("guest"));

  const [homeData, setHomeData] = useState(null);
  const [noContent, setNoContent] = useState(false);
  const [error, setError] = useState(false);
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false);

  const HOME = "home";

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
    scrollRef?.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  };

  const handleDisplayOnBoarding = (value) => {
    dispatch(displayOnBoardingActionCreator(value));
  };

  useEffect(() => {
    !!isGuestFromParams && handleDisplayOnBoarding(!isGuestFromParams);
  }, []);

  useEffect(() => {
    if (!!Object.keys(userData)?.length) {
      !!userData.username && logUserAnalyticService(
        userData.appname,
        userData.username,
        PAGE_VIEW_ACTION,
        PAGE_ID_KEY,
        HOME_VALUE
      );
    }
  }, [userData]);

  useEffect(() => {
    const userToken = getToken(keycloak);
    if (!!userToken) {
      !!userData.username &&  logUserAnalyticService(
        userData.appname,
        userData.username,
        PAGE_VIEW_ACTION,
        PAGE_ID_KEY,
        HOME_VALUE,
        userToken
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainScreenCallback = (r) => {
    if (r.message?.includes("Recharge")) {
      setSessionExpiredModal(true);
      setTimeout(() => {
        keycloak.login({ locale: MULTITENANT_CONSTANTS[TENANT]?.defaultLocale });
      }, 1500);
    } else {
      turnOffLoading();
      setSessionExpiredModal(false);
      setHomeData({ widgets: r.widgets });
      if (!r?.widgets || r?.widgets?.length === 0 || r.widgets.every(widget => !widget.contents || widget.contents.length === 0)) {
            setNoContent(true);
      }
    }
  };

  const mainScreenErrorCallback = (err) => {
    turnOffLoading();
    setError(err);
  };

const callScreenUser = () => {
  if ((initialized || isCordovaAvailable()) && !!keycloak) {
    const userToken = getToken(keycloak);
    const decodedToken = !!userToken && jwtDecode(userToken);
    const userName = decodedToken?.userName || decodedToken?.preferred_username || "";
    turnOnLoading();
    if (!screenId) {
      getMainScreenService(
          keycloak,
          MULTITENANT_CONSTANTS[TENANT].tenantServer,
          userToken,
          mainScreenCallback,
          mainScreenErrorCallback,
          userName
      );
    } else {
      getScreenService(
          screenId,
          MULTITENANT_CONSTANTS[TENANT].tenantServer,
          userToken,
          mainScreenCallback,
          mainScreenErrorCallback,
          userName
      );
    }
  }
}

useEffect(() => {
if ((initialized || isCordovaAvailable()) && !!keycloak) {
  callScreenUser();
}
}, [initialized, keycloak, screenId]);

const selectedVideo = (id) => {
let username = !!userData.username ? userData.username :  USER_ANONYMOUS;
const userToken = getToken(keycloak);
!!username && logUserAnalyticService(
  TENANT,
  username,
  PAGE_VIEW_ACTION,
  PAGE_ID_KEY,
  HOME_VALUE,
  userToken,
  id
);
};

let defaultBackground = document
.getElementById("root")
.style.getPropertyValue("--bannerWidgetImageColor");

let defaultBackgroundColor = document
.getElementById("root")
.style.getPropertyValue("--bannerWidgetBgColor");

let defaultTitleColor = document
.getElementById("root")
.style.getPropertyValue("--rowTitleColor");



return (
<>
  <MenuApiComponent {...props} localSectionSelected={screenId} />
  <div className={"WithMenuContainer"}>
    <div className="Home">
      {homeData?.widgets?.map((a, index) => {
        let background = a.backgroundimage?.data?.url ?? defaultBackground;
        let backgroundColor = a.backgroundcolor || defaultBackgroundColor;
        let titleColor = a.textcolor || defaultTitleColor;

        return a.title && !!a.contents?.length ? (
          <div key={`${a.id}_${index}`} className={"containerWidget"}>
            {!!a.type &&
            !widgetTypesWithoutSeparateSpace.includes(
              a.type.toUpperCase()
            ) ? (
              <div className="FrameworkHomeBackgroundContainer">
                <div
                  className="FrameworkHomeBackground"
                  style={{
                    backgroundImage: background,
                    backgroundColor: backgroundColor,
                  }}
                ></div>
              </div>
            ) : null}
            <Playlist
              className="FrameworkHome"
              clickCallback={selectedVideo}
              id={a.id}
              hasMoreContents={
                !!a?.filter_count && a?.filter_count < a?.contents?.length
              }
              playlist={a.contents}
              hideSubtitle={true}
              title={getWidgetTitle(a)}
              turnOffLoading={turnOffLoading}
              turnOnLoading={turnOnLoading}
              typeBanner={a.type}
              trigger={a.trigger.toUpperCase()}
              rotateTime={a.rotateTime ?? DEFAULT_SLIDE_ROTATION_INTERVAL}
              titleColor={titleColor}
              pageLocation={HOME}
            />
          </div>
        ) : null;
      })}
      {sessionExpiredModal && (
          <ErrorMessageModalComponent
              textTitle="error.modal.activation.user.title"
              textBody="error.modal.activation.user.message"
              showButtonAction={false}
          />
      )}
      {noContent && <NoContentComponent />}
      {error && <AlertNotification />}
    </div>
  </div>
  <Footer />
</>
);
};

export default WithAuthorization(HomeComponent);
