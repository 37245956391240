import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useLocation, NavLink } from "react-router-dom";
import { getSectionName , checkMenuActive } from "../../../../../Utils/utils";
import {ACTIVE_CLASS } from "../../../../../Utils/Constants";
const MenuSectionsComponent = ({ sections, sectionSelected, changeSection, getIconSection, reloadIconFlag }) => {
  const location = useLocation();

  useEffect(() => {}, [reloadIconFlag]);

  return (
    <div>
      {!!sections
        ? sections.map((section, index) => (
          <div key={`${section?.section.name}-${index}`}>
            <NavLink
              to={{
                pathname: section.url,
                aboutProps: {
                  dinamic: section.section.screenId,
                },
              }}
              onClick={() => changeSection(section.section)}
              className={`MenuSection ${section.section.iconId} ${location.pathname === section.url ? ACTIVE_CLASS : ""
                }`}
              isActive={(match, location) =>
                checkMenuActive(match, location, section, sectionSelected, sections)
              }
            >
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                placement="bottom"
                overlay={(props) =>  <Tooltip {...props}>{getSectionName(section.section)}</Tooltip>
                }
              >
                  {getIconSection(section.section?.iconsvg || section.section.iconId)}
              </OverlayTrigger>
            </NavLink>
          </div>
        ))
        : ""}
    </div>
  );
};

export default MenuSectionsComponent;
