import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./ErrorPlayerModalComponent.css";

const ErrorPlayerModalComponent = ({ refModal }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const reloadPage = () => {
        window.location.reload();
    };

    const navigateToHome = () => {
        history.push("/");
    };

    return (
        <div className="ModalBackground">
            <div
                className="ModalContainer"
                ref={refModal}
                onClick={(e) => e.stopPropagation()}
            >
                <p className="ModalTitle">{t("player.error.message")}</p>
                <div className="ButtonContainer">
                <button
                        onClick={navigateToHome}
                        className="Button Button--primary"
                    >
                        {t("player.error.home")}
                    </button>
                    <button
                        onClick={reloadPage}
                        className="Button Button--danger"
                    >
                        {t("player.error.reload")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorPlayerModalComponent;