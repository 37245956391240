import React, { useEffect, useState } from "react";
import useDynamicRefs from "use-dynamic-refs";
import "react-multi-carousel/lib/styles.css";

import "@detail/Pages/DetailPage/CarrouselStyle.css";
import {
  DATA_LOADING_STATUS_LOADED,
  WIDGET_TYPE_CIRCULAR,
  WIDGET_TYPE_GROUP_WIDGET,
  WIDGET_TYPE_HIGHLIGHT,
  WIDGET_TYPE_LIVE,
  WIDGET_TYPE_LIVE_2,
  WIDGET_TYPE_LIVE_VERTICAL,
  WIDGET_TYPE_NEWS,
  WIDGET_TYPE_GRID,
  WIDGET_TYPE_BANNER,
  WIDGET_TYPE_POSTER,
  WIDGET_TYPE_LIVE_V2,
  WIDGET_TYPE_PROGRESS,
  WIDGET_TYPE_GRID_GROUP,
  WIDGET_TYPE_HIGHLIGHT_V2,
  ORIGIN_HOME,
  WIDGET_TYPE_FEATURED,
  WIDGET_TYPE_FILTER_LIST,
  WIDGET_TYPE_LIST_SCREEN,
  WIDGET_TYPE_BANNER_CLICK,
  WIDGET_TYPE_LIST_SCREEN_GRID,
  WIDGET_TYPE_FILTER,
} from "../../../../app/src/Utils/Constants";
import LiveWidgetComponent from "./Widgets/LiveWidgetComponent";
import HighlightWidgetComponent from "./Widgets/HighlightWidgetComponent";
import GroupWidgetComponent from "./Widgets/GroupWidgetComponent";
import CircularWidgetComponent from "./Widgets/CircularWidgetComponent";
import DefaultWidgetComponent from "./Widgets/DefaultWidgetComponent";
import TextWidgetComponent from "./Widgets/TextWidgetComponent";
import FilterWidgetComponent from "./Widgets/FilterWidgetComponent";
import LiveVerticalWidgetComponent from "./Widgets/LiveVerticalWidgetComponent";
import Live_2_WidgetComponent from "./Widgets/Live_2_WidgetComponent";
import DefaultWidgetComponent_V2 from "../../../../app/src/Components/widget_v2/DefaultWidgetComponent_V2";
import WidgetHighlight_v2 from "../../../../app/src/Components/widgetHightligth_v2/WidgetHighlight_v2";
import {
  URL_CONTENT,
  URL_SCREEN,
  URL_SECTION,
  URL_WIDGET,
} from "../../../../app/src/Components/Routes/Location";
import useWindowDimensions from "../../../../app/src/Components/DOM/Screen/ScreenDimensions";
import ListScreenComponent from "./Widgets/ListScreenComponent";
import ListScreenGridComponent from "./Widgets/ListScreenGridComponent";
import BannerClickComponent from "./Widgets/BannerClickComponent";

const Playlist = (props) => {
  const { isSmall } = useWindowDimensions();
  const isMobile = window.innerWidth <= 480;

  const [getRef, setRef] = useDynamicRefs();

  const {
    turnOffLoading,
    turnOnLoading,
    trigger,
    titleColor,
    contentId = null,
    pageLocation = null,
    statusLoader = DATA_LOADING_STATUS_LOADED,
    origin = ORIGIN_HOME,
    playlist,
    title,
  } = props;

  const [playSpinner, setPlaySpinner] = useState(true);

  useEffect(() => {
    if (!!playlist?.length) {
      if (!!playSpinner) setPlaySpinner(false);
    }
  }, [playlist]);

  const renderWidget = () => {
    const typeBannerUpercase = props?.typeBanner?.toUpperCase();
    switch (typeBannerUpercase) {
      case WIDGET_TYPE_HIGHLIGHT.toUpperCase():
      case WIDGET_TYPE_FEATURED.toUpperCase():
        return (
          <HighlightWidgetComponent
            playlist={playlist}
            rotateTime={props.rotateTime}
            setRef={setRef}
            getRef={getRef}
            trigger={trigger}
            clickCallback={props.clickCallback}
            playSpinner={playSpinner}
          />
        );
      case WIDGET_TYPE_HIGHLIGHT_V2.toUpperCase():
        return (
          <WidgetHighlight_v2
            playlist={playlist}
            rotateTime={props.rotateTime}
            setRef={setRef}
            getRef={getRef}
            trigger={trigger}
            clickCallback={props.clickCallback}
            playSpinner={playSpinner}
          />
        );
      case WIDGET_TYPE_LIVE_VERTICAL:
        return (
          <LiveVerticalWidgetComponent
            key={props.key}
            className={props.className}
            style={props.style}
            title={title}
            isSmall={isSmall}
            typeBanner={props.typeBanner}
            playlist={playlist}
            trigger={trigger}
            clickCallback={props.clickCallback}
            hideSubtitle={props.hideSubtitle}
            playSpinner={playSpinner}
            titleColor={titleColor}
          />
        );
      case WIDGET_TYPE_LIST_SCREEN:
        return (
          <ListScreenComponent
            key={props.key}
            className={props.className}
            style={props.style}
            trigger={trigger}
            title={title}
            turnOnLoading={turnOnLoading}
            hasMoreContents={props.hasMoreContents}
            id={props.id}
            typeBanner={props.typeBanner}
            playSpinner={playSpinner}
            playlist={props.playlist}
            titleColor={titleColor}
            contentId={contentId}
            action={URL_SCREEN}
          />
        );
      case WIDGET_TYPE_LIST_SCREEN_GRID:
        return (
          <ListScreenGridComponent
            key={props.key}
            className={props.className}
            style={props.style}
            trigger={trigger}
            title={title}
            turnOnLoading={turnOnLoading}
            hasMoreContents={props.hasMoreContents}
            id={props.id}
            typeBanner={props.typeBanner}
            playSpinner={playSpinner}
            playlist={props.playlist}
            titleColor={titleColor}
            contentId={contentId}
            action={URL_SCREEN}
          />
        );
      case WIDGET_TYPE_BANNER_CLICK:
        return (
          <BannerClickComponent
            playlist={props.playlist}
            title={props.titulo}
          />
        );
      case WIDGET_TYPE_LIVE:
        return (
          <LiveWidgetComponent
            key={props.key}
            className={props.className}
            style={props.style}
            title={title}
            typeBanner={props.typeBanner}
            playlist={playlist}
            trigger={trigger}
            clickCallback={props.clickCallback}
            playSpinner={playSpinner}
            titleColor={titleColor}
          />
        );
      case WIDGET_TYPE_LIVE_2:
        return (
          <Live_2_WidgetComponent
            key={props.key}
            className={props.className}
            style={props.style}
            title={title}
            isSmall={isSmall}
            typeBanner={props.typeBanner}
            playlist={playlist}
            trigger={trigger}
            clickCallback={props.clickCallback}
            hideSubtitle={props.hideSubtitle}
            playSpinner={playSpinner}
            titleColor={titleColor}
          />
        );
      case WIDGET_TYPE_GROUP_WIDGET:
        return (
          <GroupWidgetComponent
            isSmall={isSmall}
            key={props.key}
            className={props.className}
            style={props.style}
            trigger={trigger}
            title={title}
            turnOnLoading={turnOnLoading}
            hasMoreContents={props.hasMoreContents}
            id={props.id}
            typeBanner={props.typeBanner}
            playSpinner={playSpinner}
            playlist={playlist}
            titleColor={titleColor}
            contentId={contentId}
            action={URL_CONTENT + URL_WIDGET}
          />
        );
      case WIDGET_TYPE_FILTER_LIST:
        return (
          <GroupWidgetComponent
            isSmall={isSmall}
            key={props.key}
            className={props.className}
            style={props.style}
            trigger={trigger}
            title={title}
            turnOnLoading={turnOnLoading}
            hasMoreContents={props.hasMoreContents}
            id={props.id}
            typeBanner={props.typeBanner}
            playSpinner={playSpinner}
            playlist={playlist}
            titleColor={titleColor}
            contentId={contentId}
            action={URL_SECTION + URL_SCREEN}
          />
        );
      case WIDGET_TYPE_CIRCULAR:
        return (
          <CircularWidgetComponent
            isSmall={isSmall}
            key={props.key}
            className={props.className}
            style={props.style}
            trigger={trigger}
            title={title}
            turnOnLoading={turnOnLoading}
            hasMoreContents={props.hasMoreContents}
            id={props.id}
            typeBanner={props.typeBanner}
            playlist={playlist}
            clickCallback={props.clickCallback}
            playSpinner={playSpinner}
            controller={props.controller}
            event={props.event}
            titleColor={titleColor}
          />
        );
      case WIDGET_TYPE_FILTER:
        return (
          <FilterWidgetComponent
            isSmall={isSmall}
            key={props.key}
            className={props.className}
            style={props.style}
            trigger={trigger}
            title={title}
            turnOffLoading={turnOffLoading}
            hasMoreContents={props.hasMoreContents}
            id={props.id}
            typeBanner={props.typeBanner}
            turnOnLoading={turnOnLoading}
            playlist={playlist}
            clickCallback={props.clickCallback}
            controller={props.controller}
            event={props.event}
            detail={props.detail}
            playSpinner={playSpinner}
            titleColor={titleColor}
            contentId={contentId}
            pageLocation={pageLocation}
            statusLoader={statusLoader}
            origin={origin}
          />
        );
      case WIDGET_TYPE_NEWS:
        return (
          <TextWidgetComponent
            isSmall={isSmall}
            key={props.key}
            className={props.className}
            style={props.style}
            title={title}
            hasMoreContents={props.hasMoreContents}
            id={props.id}
            typeBanner={props.typeBanner}
            playlist={playlist}
            clickCallback={props.clickCallback}
            playSpinner={playSpinner}
            controller={props.controller}
            event={props.event}
            titleColor={titleColor}
          />
        );
      case WIDGET_TYPE_GRID.toUpperCase():
      case WIDGET_TYPE_BANNER.toUpperCase():
      case WIDGET_TYPE_LIVE_V2.toUpperCase():
      case WIDGET_TYPE_POSTER.toUpperCase():
      case WIDGET_TYPE_PROGRESS.toUpperCase():
      case WIDGET_TYPE_GRID_GROUP.toUpperCase():
        return (
          <DefaultWidgetComponent_V2
            trigger={trigger}
            backgroundColor={props.backgroundColor}
            backgroundImage={props.backgroundImage}
            event={props.event}
            hasMoreContents={props.hasMoreContents}
            id={props.id}
            playlist={playlist}
            playSpinner={playSpinner}
            titleColor={titleColor}
            title={title}
            typeBanner={typeBannerUpercase}
            isSmall={isMobile && typeBannerUpercase === WIDGET_TYPE_POSTER.toUpperCase()}
          />
        );
      default:
        return (
          <DefaultWidgetComponent
            isSmall={isSmall}
            key={props.key}
            className={props.className}
            style={props.style}
            trigger={trigger}
            title={title}
            turnOffLoading={turnOffLoading}
            hasMoreContents={props.hasMoreContents}
            id={props.id}
            typeBanner={props.typeBanner}
            turnOnLoading={turnOnLoading}
            playlist={playlist}
            clickCallback={props.clickCallback}
            controller={props.controller}
            event={props.event}
            detail={props.detail}
            playSpinner={playSpinner}
            titleColor={titleColor}
            contentId={contentId}
            pageLocation={pageLocation}
            statusLoader={statusLoader}
            origin={origin}
          />
        );
    }
  };
  const widget = props?.typeBanner && renderWidget();
  return <>{widget}</>;
};

export default Playlist;