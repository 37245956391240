import React, { useEffect, useState } from "react";
import "./LoginRegisterRecoverForm.css";
import LoginFormBackend from "./Login/LoginFormBackend";
import { FORM_STEPS } from "../../Utils/Constants";
import { useDispatch } from "react-redux";
import { turnOffLoadingActionCreator } from "../../actions/commonActions";
import { getMenu } from "../../services/services";
import RegisterV2 from "../../../../user/src/Pages/Register/RegisterV2";
import RecoverFormBackend from "./Recover/RecoverFormBackend";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { URL_HOME } from "../../Components/Routes/Location";

const LoginRegisterRecoverForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [formStep, setFormStep] = useState(FORM_STEPS.LOGIN);
    const [tenantLanguages, setTenantLanguages] = useState([]);

    const turnOffLoading = () => {
        dispatch(turnOffLoadingActionCreator());
    };

    const getMenuCallback = (data) => {
        setTenantLanguages(data?.languages);
        renderCssValues(data);
        turnOffLoading();
    }

    const getMenuErrorCallback = (error) => {
        setTenantLanguages(["es", "en"]);
        turnOffLoading();
    }

    useEffect(() => {
        getMenu(getMenuCallback, getMenuErrorCallback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = () => {
        history.push(URL_HOME);
    }

    const handleNavigateAsGuest = () => {
        history.push(URL_HOME);
    }

    const renderCssValues = (conf) => {
        const root = document.documentElement;
        root.style.setProperty("--textFont", conf.font);
        root.style.setProperty("--backgroundColor", conf.backgroundcolor);
        root.style.setProperty("--backgroundImage", conf.backgroundimage);
        root.style.setProperty("--appColor", conf.appcolor);
        root.style.setProperty("--textColor", conf.textcolor);
        root.style.setProperty("--titleFont", conf.titlefont);
        root.style.setProperty("--titleColor", conf.titlecolor);
        root.style.setProperty("--hoverColor", conf.hovercolor);
        root.style.setProperty("--focusColor", conf.focuscolor);

        root.style.setProperty("--defaultButtonColor", conf.buttondefaultbackgroundcolor);
        root.style.setProperty("--defaultButtonTextColor", conf.buttondefaulttextcolor);
        root.style.setProperty("--defaultButtonBorderColor", conf.buttondefaultbordercolor);

        root.style.setProperty("--hoverButtonColor", conf.buttonhoverbackgroundcolor);
        root.style.setProperty("--hoverButtonBorderColor", conf.buttonhoverbordercolor);
        root.style.setProperty("--hoverButtonTextColor", conf.buttonhovertextcolor);

        root.style.setProperty("--focusedButtonColor", conf.buttonfocusedbackgroundcolor);
        root.style.setProperty("--focusedButtonBorderColor", conf.buttonfocusedbordercolor);
        root.style.setProperty("--focusedButtonTextColor", conf.buttonfocusedtextcolor);

        root.style.setProperty("--footerBackgroundColor", conf.footerbackgroundcolor);

        root.style.setProperty("--headerBackgroundColor", conf.headerbackgroundcolor);
        root.style.setProperty("--headerIconColor", conf.headericoncolor);
        root.style.setProperty("--headerHoverIconColor", conf.headerhovericoncolor);

        root.style.setProperty("--bannerWidgetBorderRadius", conf.bannerborderradius + '%');

        root.style.setProperty("--sidebarIconColor", conf.sidebariconcolor);
        root.style.setProperty("--sidebarHoverIconColor", conf.sidebarhovericoncolor);

        root.style.setProperty("--bannerWidgetTitleColor", conf.widgetbannertitlecolor);
        root.style.setProperty("--bannerWidgetImageColor", conf.widgetbannerimagecolor);
        root.style.setProperty("--bannerWidgetBgColor", conf.widgetbannerbgcolor);

        root.style.setProperty("--discoverDropBgColor", conf.discoverdropbgcolor);

        root.style.setProperty("--logo", `url(${conf.logo})`);
        root.style.setProperty("--tagBackgroundColor", conf.tagbackgroundcolor);
        root.style.setProperty("--tagTextColor", conf.tagtextcolor);
        root.style.setProperty("--featuredWidgetTitleColor", conf.widgetdestacadotitlecolor);
    };

    return (
        <div className="Page">
            {formStep === FORM_STEPS.LOGIN && (
                <div className="Form">
                    <LoginFormBackend
                        tenantLanguages={tenantLanguages}
                        changeFormStep={setFormStep}
                        handleLogin={handleLogin}
                        handleNavigateAsGuest={handleNavigateAsGuest}
                    />
                </div>
            )}
            {formStep === FORM_STEPS.REGISTER && (
                <RegisterV2 changeFormStep={setFormStep} />
            )}
            {formStep === FORM_STEPS.RECOVER && (
                <>
                    <div className="PageBackground"></div>
                    <div className="Form">
                        <RecoverFormBackend
                            tenantLanguages={tenantLanguages}
                            changeFormStep={setFormStep}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
export default LoginRegisterRecoverForm;
