import React from 'react';
import { useTranslation } from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {URL_HOME, URL_LOGIN} from "../../../../../app/src/Components/Routes/Location";
import {isCordovaAvailable} from '../../../../../app/src/Utils/secureStorageUtil';
import {Link} from "react-router-dom";

const RegisterNavigationComponent  = ({handlerBeforePage, handlerNextPage, finish, followButtonDisabled, noNext = false}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const handleLoginClick = () => {
        if (isCordovaAvailable()) {
            handlerNextPage();
        } else {
            history.push(URL_LOGIN);
        }
    };

    const handleEnterWithoutRegisterClick = () => {
        if (!isCordovaAvailable()) {
            history.push(`${URL_LOGIN}${URL_HOME}`);
        } else {
            history.push(`${URL_HOME}`);
        }
    };

    return <div className={"registerNavigation"}>
            <div className={!noNext ? "buttonGroupNavigation" : "buttonGroupNavigation noNext"}>
                <div className={"button-secondary button"} onClick={handlerBeforePage}>
                    {
                        !handlerBeforePage ?
                            <Link onClick={handleLoginClick} className={"link-secondary"}>{
                                !!finish ? t('register.gotToLogin') : t('register.cancelButton')
                            }</Link>
                            : t('register.before')
                    }
                </div>
                {!noNext &&
                    <div className={!followButtonDisabled ? "button-primary button" : "button-primary button disabled"}
                         onClick={!followButtonDisabled ? handlerNextPage : ''}>
                        {t('register.next')}
                    </div>}
            </div>

        <div className={"subTitle"}>
            <Link onClick={handleEnterWithoutRegisterClick}>{t('register.enterWithoutRegister')}</Link>
        </div>

    </div>
}

export default RegisterNavigationComponent;
