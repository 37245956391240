import React from "react";
import SearchEmpty from "../../../../../app/src/Resources/Images/empty.png";
import {useTranslation} from 'react-i18next';


const NoContentComponent = () => {

    const {t} = useTranslation();

    return (
        <div className={"contentSearch"}>
            <img
                className="NoResultsImage"
                src={SearchEmpty}
                alt="SearchEmpty"
                />
            <div className="NoResultsText1">{t("contents.no_contents_title")}</div>
            <div className="NoResultsText2">{t("contents.no_contents_subtitle")}</div>
        </div>)
};

export default NoContentComponent;