import { URL_CONTENT, URL_PLAYER, URL_SCREEN, URL_VIDEODETAIL, URL_WIDGET } from "../Components/Routes/Location";
import {
  TYPE_BANNER_BANNER,
  TYPE_BANNER_LIVE,
  TYPE_BANNER_LIVE_2,
  TYPE_BANNER_LIVE_VERTICAL,
  TYPE_BANNER_POSTER,
  TYPE_BANNER_PROGRESS,
  TYPE_BANNER_TEXT,
} from "../../../detail/src/Pages/DetailPage/PlaylistConstants";

export const ENVIRONMENT = process.env.REACT_APP_CORE_ENV;

export const USER_DEFAULT = "default-web";
export const WIDGET_TYPE_HIGHLIGHT = "DESTACADO";
export const WIDGET_TYPE_LIVE = "LIVE";
export const WIDGET_TYPE_LIVE_2 = "LIVE-2";
export const WIDGET_TYPE_LIVE_VERTICAL = "LIVEVERTICAL";
export const WIDGET_TYPE_GROUP_WIDGET = "GROUPWIDGET";
export const WIDGET_TYPE_GROUP_SCREEN = "SCREENWIDGET";
export const WIDGET_TYPE_CIRCULAR = "CIRCULAR";
export const WIDGET_TYPE_NEWS = "NEWS";
export const WIDGET_TYPE_FEATURED = "FEATURED";
export const WIDGET_TYPE_PROGRESS_V1 = "WIDGET_PROGRESS";
export const WIDGET_TYPE_HIGHLIGHT_V2 = "featured-v2";
export const WIDGET_TYPE_GRID = "GRID-V2";
export const WIDGET_TYPE_GRID_GROUP = "GRID_GROUP-V2";
export const WIDGET_TYPE_LIVE_V2 = "DIRECTO-V2";
export const WIDGET_TYPE_POSTER = "POSTER-V2";
export const WIDGET_TYPE_PROGRESS = "WIDGET_PROGRESS_V2";
export const WIDGET_TYPE_BANNER = "BANNER-V2";
export const WIDGET_TYPE_FEATURED_SLIDER = "FEATURED_SLIDER";
export const WIDGET_TYPE_BANNER_SLIDER = "BANNER_SLIDER";
export const WIDGET_TYPE_BANNER_CLICK = "BANNER-CLICK";
export const WIDGET_TYPE_FEATURED_V2 = "FEATURED-V2";
export const WIDGET_TYPE_FILTER_LIST = "FILTER-LIST";
export const WIDGET_TYPE_LIST_SCREEN = "LIST-SCREEN";
export const WIDGET_TYPE_LIST_SCREEN_GRID = "LIST-SCREEN-GRID";
export const TYPE_BANNER_BANNER_LARGE = "BANNER_LARGE";
export const WIDGET_TYPE_FILTER = "FILTER";
export const TRANSITION_DURATION = "250";
export const LIMIT_ITEMS = "5";

export const ORIGIN_SECTIONS = "ORIGIN_SECTIONS";
export const ORIGIN_HOME = "ORIGIN_HOME";

export const DATA_LOADING_STATUS_LOADED = "LOADED";
export const DATA_LOADING_STATUS_LOADING = "LOADING";

export const SWITCHER_WIDTH = 768;

export const TVG = "TVG";

export const ANIMATION_GET_GOAL_PLAY = "playingAnimationGetGoal";
export const ANIMATION_GET_GOAL_ACHIEVEMENTS = "achievements";
export const ANIMATION_GET_GOAL_REWARDS = "rewards";
export const ACTIVE_CLASS = "active";
export const TYPE_REWARD_BADGE = "badge";
export const TYPE_REWARD_CERT = "cert";
export const TYPE_REWARD_HOOK = "hook";

export const WIDGET_ACTION_PLAY = "PLAY";
export const WIDGET_ACTION_PLAYER = "PLAYER";
export const WIDGET_ACTION_DETAIL = "DETAIL";
export const WIDGET_ACTION_FULL_LIST = "FULL_LIST";
export const WIDGET_ACTION_FULLLIST = "FULLLIST";
export const WIDGET_ACTION_SCREEN = "SCREEN";

export const KEYCLOAK_URI = process.env.REACT_APP_KC_BASE_URI + "/realms/" + process.env.REACT_APP_CORE_TENANT + "/protocol/openid-connect/token";
export const KEYCLOAK_USERINFO_URI = process.env.REACT_APP_KC_BASE_URI + "/realms/" + process.env.REACT_APP_CORE_TENANT + "/protocol/openid-connect/userinfo";
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const APPNAME = process.env.REACT_APP_CORE_TENANT;

export const FORM_STEPS = {
  LOGIN: "login",
  REGISTER: "register",
  RECOVER: "recover",
}

export const DEFAULT_AVATAR_ID = 13;

export const ROUTES_BY_WIDGET_ACTION = {
  [WIDGET_ACTION_PLAY]: {
    ROUTE: URL_PLAYER,
    TEXT: "seeMore",
    SHOW_BUTTONS: true,
  },
  [WIDGET_ACTION_PLAYER]: {
    ROUTE: URL_PLAYER,
    TEXT: "seeMore",
    SHOW_BUTTONS: true,
  },
  [WIDGET_ACTION_DETAIL]: {
    ROUTE: URL_VIDEODETAIL,
    TEXT: "seeDetail",
    SHOW_BUTTONS: true,
  },
  [WIDGET_ACTION_FULL_LIST]: {
    ROUTE: URL_CONTENT + URL_WIDGET,
    TEXT: "seeDetail",
    SHOW_BUTTONS: false,
  },
  [WIDGET_ACTION_FULLLIST]: {
    ROUTE: URL_CONTENT + URL_WIDGET,
    TEXT: "seeDetail",
    SHOW_BUTTONS: false,
  },
  [WIDGET_ACTION_SCREEN]: {
    ROUTE: URL_SCREEN,
    TEXT: "seeDetail",
    SHOW_BUTTONS: false,
  },
};

export const ANDROID_DEVICES = ["Android"];
export const IOS_DEVICES = ["iPhone", "iPod", "iPad"];

export const DEVICE_IPHONE = "iPhone";
export const DEVICE_IPAD = "iPad";
export const DEVICE_IPOD = "iPod";
export const DEVICE_ANDROID = "Android";
export const DEVICE_IOS = "iOS";

export const DEVICES = {
  [DEVICE_ANDROID]: DEVICE_ANDROID,
  [DEVICE_IPHONE]: DEVICE_IOS,
  [DEVICE_IPOD]: DEVICE_IOS,
  [DEVICE_IPAD]: DEVICE_IOS,
};

export const VIDEO_TYPE_VOD_360 = "VOD_360";
export const VIDEO_TYPE_LIVE = "LIVE";
export const VIDEO_TYPE_TIIVII_LIVE = "TIIVIILIVE"
export const VIDEO_TYPE_LIVE_TIIVII = "LIVE_TIIVII";
export const VIDEO_TYPE_VOD_FORKED = "VOD_FORKED";
export const VIDEO_TYPE_VOD_CONTAINER = "CONTAINER";
export const VIDEO_TYPE_VOD_AR = "AR";
export const VIDEO_TYPE_VOD_VR = "VR";
export const VIDEO_TYPE_BASICVIDEO = "BASICVIDEO";

export const MAX_NOTIFICATIONS = 9;
export const DISPLAY_NOTIFICATIONS = `${MAX_NOTIFICATIONS}+`;


export const TIME_ZONE_EUROPE_BERLIN = "Europe/Berlin";
export const DEFAULT_TIME_ZONE = TIME_ZONE_EUROPE_BERLIN;

export const SHOW_LENGUAJE = "lenguaje";
export const SHOW_SEARCH = "search";
export const ESCAPE_KEY = "Escape";

export const TYPE_BANNER_CONVERSION = {
  circular: WIDGET_TYPE_CIRCULAR,
  banner: TYPE_BANNER_BANNER,
  "banner-v2": WIDGET_TYPE_BANNER,
  "grid-v2": WIDGET_TYPE_GRID,
  widget_progress: TYPE_BANNER_PROGRESS,
  "widget_progress-v2": WIDGET_TYPE_PROGRESS,
  featured: WIDGET_TYPE_FEATURED,
  "featured-v2": WIDGET_TYPE_FEATURED_V2,
  banner_large: TYPE_BANNER_BANNER_LARGE,
  "directo-v2": WIDGET_TYPE_LIVE_V2,
  live: TYPE_BANNER_LIVE,
  "live-2": TYPE_BANNER_LIVE_2,
  "live-vertical": TYPE_BANNER_LIVE_VERTICAL,
  "filter-list": WIDGET_TYPE_GRID_GROUP,
  filter: WIDGET_TYPE_GRID,
  poster: TYPE_BANNER_POSTER,
  "poster-v2": WIDGET_TYPE_POSTER,
  news: TYPE_BANNER_TEXT,
  DESTACADO: WIDGET_TYPE_HIGHLIGHT,
  LIVE: TYPE_BANNER_LIVE,
  BANNER: TYPE_BANNER_BANNER,
  "FILTER-LIST": WIDGET_TYPE_GRID_GROUP,
  DEFAULT_TYPE: WIDGET_TYPE_GRID,
  "list-screen-grid": WIDGET_TYPE_LIST_SCREEN_GRID,
};

export const widgetTypesWithoutSeparateSpace = [
  WIDGET_TYPE_HIGHLIGHT.toUpperCase(),
  WIDGET_TYPE_GRID.toUpperCase(),
  WIDGET_TYPE_LIVE_V2.toUpperCase(),
  WIDGET_TYPE_POSTER.toUpperCase(),
  WIDGET_TYPE_PROGRESS.toUpperCase(),
  WIDGET_TYPE_BANNER.toUpperCase(),
  WIDGET_TYPE_FEATURED_V2.toUpperCase(),
  WIDGET_TYPE_FEATURED.toUpperCase(),
  WIDGET_TYPE_BANNER_CLICK.toUpperCase(),
  WIDGET_TYPE_LIST_SCREEN_GRID.toUpperCase(),
];

export const LOCAL_STORAGE_KEY__LOCALE = "petisgo-locale";
export const LOCAL_STORAGE_KEY__SKIP_ONBOARDING = "petisgo-skip-onboarding";
export const LOCAL_STORAGE_KEY__SECTION_ID = "OTT-SectionID";
export const LOCAL_STORAGE_KEY__SECTION_URL = "OTT-URLSection";
export const LOCAL_STORAGE_KEY__SELECTED_SECTION = "OTT-Section";

export const INTERFACE_MAIN = "MAIN";
export const INTERFACE_LOGGED_IN = "LOGADO";
export const INTERFACE_PETISGO_ADULT = "Interfaz adultos"

export const SECTION_TYPE_SCREEN = "screen";

export const DEFAULT_INTERFACE = {
  apibase: "https://petisgo.tiivii.com/v1",
  //appcolor: "#0E121D",
  //backgroundcolor: "#0E121D",
  //backgroundimage: null,
  //bannerborderradius: 2,
  //buttondefaultbackgroundcolor: "#F84B4F",
  //buttondefaultbordercolor: "#F84B4F",
  //buttondefaulttextcolor: "#0E121D",
  //buttonfocusedbackgroundcolor: "#F84B4F",
  //buttonfocusedbordercolor: "#F84B4F",
  //buttonfocusedtextcolor: "#F0E9E3",
  //buttonhoverbackgroundcolor: "#0E121D",
  //buttonhoverbordercolor: "#F84B4F",
  //buttonhovertextcolor: "#FFFFFF",
  catalogueheadbackgroundcolor: "#0E121D",
  detailpagebgcolor: "#0E121D",
  detailpagebgimage: null,
  differentlogins: [
    {
      clientid: "default-web",
      id: 2,
      kcauthuri: "https://watch.ohjazz.tv/auth/realms/andromeda/protocol/openid-connect/auth",
      kcbase: "https://watch.ohjazz.tv/auth/",
      kclogouturi: "https://watch.ohjazz.tv/auth/realms/andromeda/protocol/openid-connect/logout",
      kcopenidconf: "https://watch.ohjazz.tv/auth/realms/andromeda/.well-known/openid-configuration",
      kctokenuri: "https://watch.ohjazz.tv/auth/realms/andromeda/protocol/openid-connect/token",
      kcuserinfouri: "https://watch.ohjazz.tv/auth/realms/andromeda/protocol/openid-connect/userinfo",
      name: "petisgo-default-web",
      title: "1",
    },
    {
      clientid: "default-app",
      id: 4,
      kcauthuri: "https://watch.ohjazz.tv/auth/realms/andromeda/protocol/openid-connect/auth",
      kcbase: "https://watch.ohjazz.tv/auth/",
      kclogouturi: "https://watch.ohjazz.tv/auth/realms/andromeda/protocol/openid-connect/logout",
      kcopenidconf: "https://watch.ohjazz.tv/auth/realms/andromeda/.well-known/openid-configuration",
      kctokenuri: "https://watch.ohjazz.tv/auth/realms/andromeda/protocol/openid-connect/token",
      kcuserinfouri: "https://watch.ohjazz.tv/auth/realms/andromeda/protocol/openid-connect/userinfo",
      name: "petisgo-default-app",
      title: "1",
    },
  ],
  discoverdropbgcolor: null,
  filtertitlecolor: "#F0E9E3",
  //focuscolor: "#0E121D",
  //font: "Montserrat",
  footer: null,
  //footerbackgroundcolor: "#0E121D",
  guestmodeavailable: true,
  header: null,
  //headerbackgroundcolor: null,
  //headerfocusiconcolor: null,
  //headerhovericoncolor: null,
  //headericoncolor: null,
  //hovercolor: "#0E121D",
  id: 1,
  languages: ["en", "es", "jp", "pt"],
  legal: {
    created_on: "2020-08-28T15:45:42+00:00",
    id: 2,
    modified_on: "2022-08-23T11:03:05+00:00",
    text: '<p>For more information clic the link below:<br /><span style="color: #000000;">&nbsp;<a class="external-link" title="Seguir el enlace" href="https://www.ohjazz.tv/terms-eng" target="_blank" rel="nofollow noopener">https://www.ohjazz.tv/terms-eng</a></span></p>\n<p><span style="color: #000000;">詳細については、以下のリンクをクリックしてください。<br /><a class="external-link" title="Seguir el enlace" href="https://www.ohjazz.tv/terms-jap" target="_blank" rel="nofollow noopener">https://www.ohjazz.tv/terms-jap</a></span></p>\n<p>Para m&aacute;s informaci&oacute;n clic en el siguiente link:<br /><span style="color: #000000;"><a class="external-link" title="Seguir el enlace" href="https://www.ohjazz.tv/terms-esp" target="_blank" rel="nofollow noopener">https://www.ohjazz.tv/terms-esp</a></span></p>',
    translations: [
      {
        id: 2,
        language: "en",
        legal: 2,
        text: '<p>For more information clic the link below:<br /><span style="color: #000000;">&nbsp;<a class="external-link" title="Seguir el enlace" href="https://www.ohjazz.tv/terms-eng" target="_blank" rel="nofollow noopener">https://www.ohjazz.tv/terms-eng</a></span></p>\n<p>&nbsp;</p>',
      },
      {
        id: 3,
        language: "es",
        legal: 2,
        text: '<p>Para m&aacute;s informaci&oacute;n clic en el siguiente link:<br /><span style="color: #000000;"><a class="external-link" title="Seguir el enlace" href="https://www.ohjazz.tv/terms-esp" target="_blank" rel="nofollow noopener">https://www.ohjazz.tv/terms-esp</a></span></p>',
      },
      {
        id: 4,
        language: "jp",
        legal: 2,
        text: '<p>詳細については、以下のリンクをクリックしてください。<br /><a class="external-link" title="Seguir el enlace" href="https://www.ohjazz.tv/terms-jap" target="_blank" rel="nofollow noopener">https://www.ohjazz.tv/terms-jap</a></p>',
      },
    ],
    version: 2,
  },
  loginbgimage: null,
  /*logo: {
    charset: "",
    checksum: "31a4376e43914cf4c369f245f8d5b0ec",
    data: {
      asset_url: "/andromeda/assets/1stgwnv7otz44sg4",
      embed: null,
      full_url:
        "https://andromeda-bis-pro.s3.eu-west-1.amazonaws.com/directus/andromeda/originals/3b6b8504-0c86-430e-a878-cd5df9d863a1.png",
      thumbnails: [
        {
          dimension: "64x64",
          height: 64,
          key: "directus-small-crop",
          relative_url: "/andromeda/assets/1stgwnv7otz44sg4?key=directus-small-crop",
          url: "http://petisgo.tiivii.com/andromeda/assets/1stgwnv7otz44sg4?key=directus-small-crop",
          width: 64,
        },
        {
          dimension: "64x64",
          height: 64,
          key: "directus-small-contain",
          relative_url: "/andromeda/assets/1stgwnv7otz44sg4?key=directus-small-contain",
          url: "http://petisgo.tiivii.com/andromeda/assets/1stgwnv7otz44sg4?key=directus-small-contain",
          width: 64,
        },
        {
          dimension: "300x300",
          height: 300,
          key: "directus-medium-crop",
          relative_url: "/andromeda/assets/1stgwnv7otz44sg4?key=directus-medium-crop",
          url: "http://petisgo.tiivii.com/andromeda/assets/1stgwnv7otz44sg4?key=directus-medium-crop",
          width: 300,
        },
        {
          dimension: "300x300",
          height: 300,
          key: "directus-medium-contain",
          relative_url: "/andromeda/assets/1stgwnv7otz44sg4?key=directus-medium-contain",
          url: "http://petisgo.tiivii.com/andromeda/assets/1stgwnv7otz44sg4?key=directus-medium-contain",
          width: 300,
        },
        {
          dimension: "800x600",
          height: 600,
          key: "directus-large-crop",
          relative_url: "/andromeda/assets/1stgwnv7otz44sg4?key=directus-large-crop",
          url: "http://petisgo.tiivii.com/andromeda/assets/1stgwnv7otz44sg4?key=directus-large-crop",
          width: 800,
        },
        {
          dimension: "800x600",
          height: 600,
          key: "directus-large-contain",
          relative_url: "/andromeda/assets/1stgwnv7otz44sg4?key=directus-large-contain",
          url: "http://petisgo.tiivii.com/andromeda/assets/1stgwnv7otz44sg4?key=directus-large-contain",
          width: 800,
        },
      ],
      url: "https://andromeda-bis-pro.s3.eu-west-1.amazonaws.com/directus/andromeda/originals/3b6b8504-0c86-430e-a878-cd5df9d863a1.png",
    },
    description: "",
    duration: 0,
    embed: null,
    filename_disk: "3b6b8504-0c86-430e-a878-cd5df9d863a1.png",
    filename_download: "oh!_jazz_horizontal_logo_white.png",
    filesize: 8222,
    folder: null,
    height: 247,
    id: 1282,
    location: "",
    metadata: {
      imagetype: "undefined",
      owner: "ohjazz",
    },
    private_hash: "1stgwnv7otz44sg4",
    storage: "s3",
    tags: [],
    title: "Horizontal White Logo",
    type: "image/png",
    uploaded_by: 2,
    uploaded_on: "2022-03-01 11:37:25",
    width: 845,
  },*/
  menu: [
    {
      interfacemenus_id: {
        child: [],
        created_on: "2021-02-25T15:38:45+00:00",
        description: null,
        device: "all",
        href: null,
        icon: "alert-outline",
        iconsvg: {
          charset: "",
          checksum: "c2e04f5c89cdf3062ad42bbc45c96ec7",
          data: {
            asset_url: "/andromeda/assets/9xd9xw8pa3kgkkgg",
            embed: null,
            full_url:
              "https://andromeda-bis-pro.s3.eu-west-1.amazonaws.com/directus/andromeda/originals/12f6699a-e578-4cf5-8395-07183279901b.png",
            thumbnails: [
              {
                dimension: "64x64",
                height: 64,
                key: "directus-small-crop",
                relative_url: "/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-small-crop",
                url: "http://petisgo.tiivii.com/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-small-crop",
                width: 64,
              },
              {
                dimension: "64x64",
                height: 64,
                key: "directus-small-contain",
                relative_url: "/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-small-contain",
                url: "http://petisgo.tiivii.com/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-small-contain",
                width: 64,
              },
              {
                dimension: "300x300",
                height: 300,
                key: "directus-medium-crop",
                relative_url: "/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-medium-crop",
                url: "http://petisgo.tiivii.com/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-medium-crop",
                width: 300,
              },
              {
                dimension: "300x300",
                height: 300,
                key: "directus-medium-contain",
                relative_url: "/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-medium-contain",
                url: "http://petisgo.tiivii.com/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-medium-contain",
                width: 300,
              },
              {
                dimension: "800x600",
                height: 600,
                key: "directus-large-crop",
                relative_url: "/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-large-crop",
                url: "http://petisgo.tiivii.com/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-large-crop",
                width: 800,
              },
              {
                dimension: "800x600",
                height: 600,
                key: "directus-large-contain",
                relative_url: "/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-large-contain",
                url: "http://petisgo.tiivii.com/andromeda/assets/9xd9xw8pa3kgkkgg?key=directus-large-contain",
                width: 800,
              },
            ],
            url: "https://andromeda-bis-pro.s3.eu-west-1.amazonaws.com/directus/andromeda/originals/12f6699a-e578-4cf5-8395-07183279901b.png",
          },
          description: "",
          duration: 0,
          embed: null,
          filename_disk: "12f6699a-e578-4cf5-8395-07183279901b.png",
          filename_download: "icons8-casa-50.png",
          filesize: 491,
          folder: null,
          height: 50,
          id: 4701,
          location: "",
          metadata: null,
          private_hash: "9xd9xw8pa3kgkkgg",
          storage: "s3",
          tags: [],
          title: "Icons8 Casa 50",
          type: "image/png",
          uploaded_by: 1,
          uploaded_on: "2024-05-22 08:36:34",
          width: 50,
        },
        id: 6,
        modified_by: {
          id: 1,
        },
        modified_on: "2024-05-22T08:36:47+00:00",
        name: "Home ",
        order: 0,
        screen: {
          id: 2,
        },
        status: "published",
        translations: [
          {
            description: "Home Screen",
            language: "en",
            name: "Home",
          },
          {
            description: "Portada",
            language: "es",
            name: "Portada",
          },
          {
            description: "Portada",
            language: "gl",
            name: "Portada",
          },
        ],
      },
    },
    {
      interfacemenus_id: {
        child: [],
        created_on: "2020-11-12T15:32:49+00:00",
        description: "catalogue",
        device: "all",
        href: "",
        icon: "compass-outline",
        iconsvg: null,
        id: 3,
        modified_by: {
          id: 1,
        },
        modified_on: "2023-07-10T12:55:35+00:00",
        name: "Discover",
        order: 3,
        screen: {
          id: 8,
        },
        status: "draft",
        translations: [
          {
            description: "Catálogo de contenidos",
            language: "es",
            name: "Catálogo",
          },
          {
            description: "Catálogo de contenidos",
            language: "gl",
            name: "Catálogo",
          },
          {
            description: null,
            language: "en",
            name: "Discover",
          },
        ],
      },
    },
    {
      interfacemenus_id: {
        child: [],
        created_on: "2023-07-10T12:55:35+00:00",
        description: "catalogue",
        device: "all",
        href: null,
        icon: "compass-outline",
        iconsvg: null,
        id: 16,
        modified_by: {
          id: 1,
        },
        modified_on: "2023-07-10T12:55:35+00:00",
        name: "Discover",
        order: 3,
        screen: null,
        status: "published",
        translations: [],
      },
    },
    {
      interfacemenus_id: {
        child: [],
        created_on: "2022-01-14T13:56:37+00:00",
        description: "Events coming up",
        device: "all",
        href: null,
        icon: "arrow-forward-outline",
        iconsvg: null,
        id: 14,
        modified_by: {
          id: 4,
        },
        modified_on: "2024-04-11T09:53:31+00:00",
        name: "What's Coming",
        order: 3,
        screen: {
          id: 9,
        },
        status: "published",
        translations: [
          {
            description: "Próximos eventos",
            language: "es",
            name: "Próximamente",
          },
          {
            description: "évènements à venir",
            language: "fr",
            name: "Bientôt disponible",
          },
          {
            description: null,
            language: "en",
            name: "What's Coming",
          },
        ],
      },
    },
    {
      interfacemenus_id: {
        child: [],
        created_on: "2022-01-25T19:53:46+00:00",
        description: "Clubs",
        device: "all",
        href: null,
        icon: "earth-outline",
        iconsvg: null,
        id: 15,
        modified_by: {
          id: 4,
        },
        modified_on: "2024-04-11T01:10:32+00:00",
        name: "Clubs",
        order: 4,
        screen: {
          id: 7,
        },
        status: "published",
        translations: [
          {
            description: "Clubes Oh! Jazz",
            language: "es",
            name: "Clubes",
          },
          {
            description: null,
            language: "en",
            name: "Clubs",
          },
        ],
      },
    },
    {
      interfacemenus_id: {
        child: [],
        created_on: "2020-11-12T15:31:41+00:00",
        description: "buscador",
        device: "all",
        href: "",
        icon: "search-outline",
        iconsvg: null,
        id: 2,
        modified_by: {
          id: 4,
        },
        modified_on: "2022-05-26T11:37:43+00:00",
        name: "search",
        order: 4,
        screen: null,
        status: "published",
        translations: [
          {
            description: "Buscador de contenidos",
            language: "es",
            name: "Buscador",
          },
          {
            description: "Buscador de contenidos",
            language: "gl",
            name: "Buscador",
          },
          {
            description: null,
            language: "en",
            name: "Search",
          },
        ],
      },
    },
    {
      interfacemenus_id: {
        child: [],
        created_on: "2020-11-12T15:33:51+00:00",
        description: "usuario",
        device: "all",
        href: "",
        icon: "options-outline",
        iconsvg: null,
        id: 4,
        modified_by: {
          id: 4,
        },
        modified_on: "2024-04-24T06:45:07+00:00",
        name: "Profile",
        order: 5,
        screen: null,
        status: "published",
        translations: [
          {
            description: "User interface",
            language: "en",
            name: "Profile",
          },
          {
            description: "Interfaz de usuario",
            language: "es",
            name: "Perfil",
          },
          {
            description: "Interfaz de usuario",
            language: "gl",
            name: "Usuario",
          },
        ],
      },
    },
  ],
  menubackgroundcolor: "#0E121D",
  privacy_url: "",
  profile: "adult",
  rankingcellcolor: null,
  rankinguserbannercolor: null,
  rowtextcolor: "#F0E9E3",
  rowtextfont: "Montserrat",
  rowtitlecolor: "#F0E9E3",
  rowtitlefont: "Montserrat",
  screens: [
    {
      id: 6,
      interfaces_id: 1,
      screens_id: 2,
    },
    {
      id: 10,
      interfaces_id: 1,
      screens_id: 6,
    },
  ],
  sidebarcolor: null,
  sidebarfocusiconcolor: null,
  //sidebarhovericoncolor: null,
  //sidebariconcolor: null,
  sponsors: [],
  status: "published",
  subcontents_label: "Shows",
  tagbackgroundcolor: null,
  tagtextcolor: null,
  widgetbannertitlecolor: null,
  terms_conditions_url: "",
  //textcolor: "#F0E9E3",
  title: "MAIN",
  //titlecolor: "#F0E9E3",
  //titlefont: "Montserrat",
  translations: [
    {
      id: 1,
      interface: 1,
      language: "en",
      privacy_url: "",
      subcontents_label: "Shows",
      terms_conditions_url: "",
    },
    {
      id: 2,
      interface: 1,
      language: "es",
      privacy_url: "",
      subcontents_label: "Shows",
      terms_conditions_url: "",
    },
    {
      id: 3,
      interface: 1,
      language: "jp",
      privacy_url: "",
      subcontents_label: "ショー",
      terms_conditions_url: "",
    },
  ],
  //widgetbannerbgcolor: "#0E121D",
  //widgetbannerimagecolor: null,
  widgetbannertextcolor: "#F0E9E3",
  //widgetbannertitlecolor: "#F0E9E3",
  widgetdestacadobgcolor: null,
  widgetdestacadotextcolor: "#FFFFFF",
  widgetdestacadotitlecolor: "#FFFFFF",
};
