import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import "../BarraSuperior.css";
import iconLive from "../../../../../Resources/Images/Icon_live.svg";
import { MENU_ID_LIVE } from "../../../MenuConstants";
import { turnOnLoadingActionCreator } from "../../../../../actions/commonActions";

const MenuLiveComponent = forwardRef((props, ref) => {
    const { userAge, rol, handleChangeSection } = props
    const dispatch = useDispatch();

  const menuSections = useSelector((state) => state.commonState.menuSections);

  const liveSection = menuSections.filter((item) => item.section.id === MENU_ID_LIVE)[0];

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const changeSection = () => {
    const section = liveSection?.section;
    turnOnLoading();
    handleChangeSection(section);
  };

  const controlClassByTheme = () => {
    return !rol ? "iconLiveChild" : userAge < 14 ? "iconLiveChild" : "iconLive";
  };

  return (
    !!liveSection && (
      <div className={"Button-Live"}>
        <NavLink
          to={{
            pathname: liveSection?.url,
            aboutProps: {
              dinamic: liveSection?.section.screenId,
            },
          }}
          onClick={() => changeSection()}
          key={liveSection?.section.screenId}
        >
          <img className={controlClassByTheme()} src={iconLive} alt="Live" />
        </NavLink>
      </div>
    )
  );
});

export default MenuLiveComponent;
