import React, { useState } from "react";
import { Col, Row, Tooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
  TENANT_PETISGO,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import "./DesactivateUserModalComponent";

const useStyles = makeStyles(() => ({
  iconContainer: {
    zIndex: 0,
  },
  disabledButton: {
    opacity: 0.6,
    backgroundColor: '#b3b3b3',
    cursor: 'not-allowed',
  },
}));

const UserButtonOptionsComponent = ({
  handlePasswordModal,
  handleDisplayOnBoarding,
  handleTermsModal,
  handleDeactivateUserModal,
  checkoutURL,
  terms,
  tutorialUrl,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleRedirectToTutorial = () => {
    window.location.href = tutorialUrl;
  };

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Col md={12}>
      <Row className={"OptionsContainer"}>
        <h2 className={"Title-bold"}>{t("perfil.otras.opciones")}</h2>
      </Row>
      <Row className={"OptionButtonsRow"}>
        {(!!tutorialUrl || !!MULTITENANT_CONSTANTS[TENANT].showOnBoarding) && (
          <div
            className={"ProfileOptionButton"}
            onClick={() =>
              !!tutorialUrl
                ? handleRedirectToTutorial()
                : handleDisplayOnBoarding(false)
            }
          >
            <div className={classes.iconContainer}>
              <ion-icon name="eye" />
            </div>
            <span>{t("perfil.watch.tutorial")}</span>
          </div>
        )}
        {!!terms && (
          <div
            className={"ProfileOptionButton"}
            onClick={() => handleTermsModal(true)}
          >
            <div className={classes.iconContainer}>
              <ion-icon name="reader" />
            </div>
            <span>{t("perfil.read.terms")}</span>
          </div>
        )}
        {MULTITENANT_CONSTANTS[TENANT].showSubscriptions && (
          <div
            className={"ProfileOptionButton"}
            onClick={() => window.open(checkoutURL)}
          >
            <div className={classes.iconContainer}>
              <ion-icon name="cart" />
            </div>
            <span>{t("perfil.watch.subscriptions")}</span>
          </div>
        )}
        <div
          className={"ProfileOptionButton"}
          onClick={() => handlePasswordModal(true)}
        >
          <div className={classes.iconContainer}>
            <ion-icon name="lock-closed" />
          </div>
          <span>{t("perfil.update.password")}</span>
        </div>
      </Row>
      <Row className={"OptionButtonsRow"}>
  {!!handleDeactivateUserModal && <div
    className={"ProfileOptionButton ProfileOptionButton--danger"}
    onClick={() => handleDeactivateUserModal(true)} 
  >
    <div className={classes.iconContainer}>
      <ion-icon name="warning" />
    </div>
    <span>{t("perfil.deactivate.account")}</span>
  </div>}
</Row>
    </Col>
  );
};

export default UserButtonOptionsComponent;
