import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";

import VideoType from "../VideoType";
import iconTVG from "@app/Resources/Images/icono_TVG.png";
import { ACCESS_PLAY } from "../PlaylistConstants";
import {
  URL_PLAYER,
  URL_VIDEODETAIL,
} from "../../../../../app/src/Components/Routes/Location";
import defaultBanner from "@app/Resources/Images/default_banner.png";
import "./LiveWidget.css";
import keycloak from "../../../../../app/src/Utils/keycloak";

const LiveVerticalWidgetComponent = ({
  key,
  className,
  style,
  title,
  playlist,
  playSpinner,
  trigger,
  clickCallback,
  hideSubtitle,
  titleColor,
}) => {
  const { t } = useTranslation();

  const getImageCard = (w) => {
    return !!w.poster || !!w.banner ? (
      <img
        className="PlaylistItemImage"
        src={!!w.poster ? w.poster : w.banner}
        alt={w.title}
      />
    ) : (
      <img className="PlaylistItemImage" src={defaultBanner} alt={w.title} />
    );
  };

  return (
    <>
      <div className="CarouselContainer">
        <div className="PlaylistContainer" key={key}>
          <div className={"Playlist " + className} style={style}>
            <div className="Titulo" style={{ display: "flex" }}>
              <p style={{ width: "auto", color: titleColor }}>{title}</p>
            </div>
            <div className="LiveGrid">
              {playlist?.length > 0 &&
                playlist?.map((w, i) => (
                  <div className={"PlaylistItemContainer"} key={i}>
                    <Link
                      to={{
                        pathname:
                          trigger === ACCESS_PLAY
                            ? URL_PLAYER + "/" + w.id + parameterGuest()
                            : URL_VIDEODETAIL + "/" + w.id + parameterGuest(),
                      }}
                    >
                      <div
                        className="PlaylistItem"
                        onClick={clickCallback ? () => clickCallback(w.id) : ""}
                        key={w.id}
                      >
                        {getImageCard(w)}
                        {w?.tags?.size !== 0 &&
                          w?.tags?.toArray().includes("TVG") && (
                            <img
                              className="iconTVG"
                              src={iconTVG}
                              alt={"iconTVG"}
                            />
                          )}
                        {w.poster === null && w.banner === null && (
                          <img
                            className="PlaylistItemImage"
                            src={defaultBanner}
                            alt={w.title}
                          />
                        )}
                        <div className="PlaylistItemTitle">{w.title}</div>
                        <div className="PlaylistItemSubtitle">{w.subtitle}</div>
                        {!hideSubtitle && (
                          <>
                            {w.episode && (
                              <div className="PlaylistItemSubtitle">
                                {t("ficha.episodio", { episodio: w.episode })}
                              </div>
                            )}
                            {!w.episode && (
                              <div className="PlaylistItemSubtitle">
                                {w.subtitle}
                              </div>
                            )}
                          </>
                        )}
                        <div
                          className="ButtonAccess"
                          onClick={
                            clickCallback ? () => clickCallback(w.id) : ""
                          }
                        >
                          <div className="Access button-focused">
                            {t("home.access")}
                          </div>
                        </div>
                      </div>
                    </Link>
                    <VideoType subtitle={w} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {playSpinner && (
        <div className="SpinnerContainer">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </>
  );
};

export default LiveVerticalWidgetComponent;
