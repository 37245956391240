import React from "react";
import { useHistory } from "react-router-dom";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import defaultBanner from "../../../../../app/src/Resources/Images/default_banner.png";
import { isCordovaAvailable } from "../../../../../app/src/Utils/secureStorageUtil";
import { URL_VIDEODETAIL } from "../../../../../app/src/Components/Routes/Location";

const BannerClickComponent = ({ playlist, title }) => {
  const history = useHistory();
  const bannerClickContent = playlist[0];
  const isMobile = isCordovaAvailable();

  const handleBannerClick = () => {
    if (bannerClickContent.apirequest === null) {
      return;
    }
    if (!isMobile) {
      window.open(bannerClickContent.apirequest, "_blank");
    } else {
      if (bannerClickContent.apirequest.includes("https://ott.tiivii.com/videodetail/") ||
        bannerClickContent.apirequest.includes("https://tiivii.gal/videodetail/")) {
        const match = bannerClickContent.apirequest.match(/\/videodetail\/(\d+)/);
        const id = match ? match[1] : null;
        if (id !== null) {
          history.push(URL_VIDEODETAIL + "/" + id);
        }
      } else {
        cordova.InAppBrowser.open(bannerClickContent.apirequest, "_system");
      }
    }
  }

  return (
    <div>
      <div
        className={`${MULTITENANT_CONSTANTS[TENANT].classPlaylistHighlightItem} bannerClick`}
        key={bannerClickContent.id + "-bannerclick"}
      >
        <div
          className={
            MULTITENANT_CONSTANTS[TENANT].bannerHighlightedHeightClassName +
            " playlistHighlightHover"
          }
          onClick={handleBannerClick}
        >
          <img
            src={
              bannerClickContent.backgroundimage
                ? bannerClickContent.backgroundimage
                : defaultBanner
            }
            alt={title}
            className={MULTITENANT_CONSTANTS[TENANT].classHeight}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerClickComponent;
