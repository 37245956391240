import React from "react";
import { ACCESS_PLAY, PLAYLIST_URL_VALUE } from "../PlaylistConstants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  URL_PLAYER,
  URL_VIDEODETAIL,
} from "../../../../../app/src/Components/Routes/Location";
import VideoType from "../VideoType";
import VideoTag from "../VideoTag";
import degraded from "@app/Resources/Images/degradado600.png";
import { actionTypeContainer } from "../../../../../app/src/Utils/utils";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import keycloak from "../../../../../app/src/Utils/keycloak";

const HighlightSlideComponent = ({
  w,
  setRef,
  defaultBanner,
  trigger,
  clickCallback,
  renderMobileVersion,
}) => {
  const { t } = useTranslation();
  const limitOfTags = 4;

  return (
    <div>
      <div
        className={MULTITENANT_CONSTANTS[TENANT].classPlaylistHighlightItem}
        key={w.id}
      >
        {!!w.background || !!w.banner ? (
          <div
            className={
              MULTITENANT_CONSTANTS[TENANT].bannerHighlightedHeightClassName
            }
          >
            <Link
              to={{
                pathname:
                  trigger === ACCESS_PLAY
                    ? URL_PLAYER + "/" + w.id
                    : URL_VIDEODETAIL + "/" + w.id,
              }}
            >
              <img
                src={
                  !!renderMobileVersion && !!w.banner ? w.banner : w.background
                }
                alt={w.title}
                ref={setRef(w.title)}
                className={MULTITENANT_CONSTANTS[TENANT].classHeight}
              />
            </Link>
          </div>
        ) : (
          <div className="PlaylistHighlight">
            <img
              className={`${MULTITENANT_CONSTANTS[TENANT].classHeight} PlaylistHighlight`}
              src={defaultBanner}
              alt={w.title}
              ref={setRef(w.title + PLAYLIST_URL_VALUE)}
            />
          </div>
        )}
        <Link
          to={{
            pathname: actionTypeContainer(trigger).ROUTE + "/" + w.id,
          }}
          className="OverContainerHome"
          style={{ backgroundImage: "url(" + degraded + ")" }}
        >
          <div className="TextHome">
            <div className="TitleHighlight">{w.title}</div>
            <div className={"HighlightTagsContainer"}>
              {MULTITENANT_CONSTANTS[TENANT].showVideoTags ? (
                <>
                  <VideoType subtitle={w} />
                  {w.tags?.map((t, index) => {
                    return index < limitOfTags ? (
                      <VideoTag tag={t} key={t + "-" + index} />
                    ) : (
                      index === limitOfTags && "..."
                    );
                  })}
                </>
              ) : (
                w.subtitle
              )}
            </div>
            <div className="HighlightInfoContainer">
              {!!w.category?.code && (
                <span className="Xenero">{w.category.code}</span>
              )}
              {w.publishDate && (
                <span className="Data">
                  {moment(w.publishDate, "YYYY-MM-DD").format("DD MMM YYYY")}
                </span>
              )}
              {w.seasons && (
                <span className="Temporadas">
                  {t("ficha.seasons", { seasons: w.seasons })}
                </span>
              )}
              {w.rating && (
                <span className="Idade">
                  {t("ficha.rating", { rating: w.rating })}
                </span>
              )}
            </div>
            {/*<Link
              to={{
                pathname: actionTypeContainer(trigger).ROUTE + "/" + w.id,
              }}
              className="ButtonAccess"
              onClick={clickCallback ? () => clickCallback(w.id) : ""}
            />*/}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HighlightSlideComponent;
