import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import style from "./style.module.css";
import styleHover from "../PlaylistItem/style.module.css";

import { useTranslate } from "../../utils/utils";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../Resources/Multitenant/tenantConstants";
import { actionTypeContainer } from "../../../../Utils/utils";
import FavButton from "../FavButton/FavButton";

const ActionButtons = ({ data, trigger, event, actionLink = true, goToLink }) => {
  const { id, canFav, isFav, video, controller } = data;
  const videoUrl = video?.url;
  const videoType = video?.type;
  const t = useTranslate();

  const showActionLink = () => {
    if (actionLink) {
      return (
        <Link
          to={{
            pathname: actionTypeContainer(trigger).ROUTE + "/" + id,
            state: {
              url: videoUrl,
              type: videoType,
              id: id,
            },
          }}
          aria-label={t("global.button.go.to.player")}
        >
          <PlayCircleOutlineIcon id={style.playIcon} />
        </Link>
      );
    } else {
      return <PlayCircleOutlineIcon id={style.playIcon} onClick={goToLink} />;
    }
  };
  return (
    <section
      className={`
            ${styleHover.playFavContainer__top}
            ${MULTITENANT_CONSTANTS[TENANT].ImageWithText ? style.playFavContainer : style.playFavContainer__top}`}
    >
      {showActionLink()}
      {canFav && (
        <FavButton
          className={style.favIcon}
          controller={controller}
          events={event}
          id={id}
          isFavorite={isFav ?? false}
          isPlaylist={true}
        />
      )}
    </section>
  );
};

ActionButtons.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    canFav: PropTypes.bool,
    isFav: PropTypes.bool,
  }).isRequired,
  trigger: PropTypes.string.isRequired,
};

export default ActionButtons;
