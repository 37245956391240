import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  URL_HOME,
  URL_NAME_PUBLISH,
  URL_NAME_REJECT,
  URL_VIDEODETAIL,
} from "../../../../app/src/Components/Routes/Location";
import { turnOffLoadingActionCreator } from "../../../../app/src/actions/commonActions";
import "./PublishRejectStyles.css";
import logoPetisgo from "../../../../app/src/Resources/Images/logoPetiscos1444-512px.png";
import cornerTop from "../../../../app/src/Resources/Images/fondo onboarding_der.png";
import cornerBottom from "../../../../app/src/Resources/Images/fondo onboarding_izq.png";
import rejectIcon from "../../../../app/src/Resources/Images/semaforo luz roja.svg";
import publishIcon from "../../../../app/src/Resources/Images/semaforo luz verde.svg";
import { publishContentService } from "../../../../app/src/services/services";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import { isCordovaAvailable } from "../../../../app/src/Utils/secureStorageUtil";

const PublishRejectComponent = ({ event }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak, initialized } = useKeycloak();
  const { contentId} = useParams();
  const query = new URLSearchParams(window.location.search);
  const payload = query.get("payload");

  const publishRejectObject = {
    [URL_NAME_PUBLISH]: {
      title: t("user.publish.title"),
      img: publishIcon,
      text: t("user.publish.text"),
    },
    [URL_NAME_REJECT]: {
      title: t("user.reject.title"),
      img: rejectIcon,
      text: t("user.reject.text"),
    },
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const publishContentCallback = () => {};

  useEffect(() => {
    turnOffLoading();
    if (!!keycloak && (initialized || isCordovaAvailable())) {
      publishContentService(payload, keycloak?.token, publishContentCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const handleRedirectToHome = () => {
    history.push(URL_HOME);
  };

  const handleRedirectToPlayer = () => {
    history.push(`${URL_VIDEODETAIL}/${contentId}`);
  };

  return (
    <div className={"publishReject"}>
      <div className={"logoApp"}>
        <img src={logoPetisgo} alt={"logo-Petisgo"} className={"logoPetisgo"} />
      </div>
      <div className={"cornerTop"}>
        <img src={cornerTop} alt={"Corner"} />
      </div>
      <div className={"content"}>
        <div className={"iconAlert"}>
          <img src={publishRejectObject[event].img} alt={"Alert icon"} />
        </div>
        <div className={"text"}>
          <h1>{publishRejectObject[event].title}</h1>
          <p>{publishRejectObject[event].text}</p>
          <div className="button-group">
            <button
              type="button"
              onClick={handleRedirectToHome}
              className="button-default-publish"
            >
              <span className="button-text">{t("user.reject.go.home")}</span>
            </button>
            {event === URL_NAME_PUBLISH && (
              <button
                type="button"
                onClick={handleRedirectToPlayer}
                className="button-default-invert-publish"
              >
                <span className="button-text">
                  {t("user.reject.go.content")}
                </span>
              </button>
            )}
          </div>
        </div>
        <div className={"cornerBottom"}>
          <img src={cornerBottom} alt={"Corner"} />
        </div>
      </div>
    </div>
  );
};

export default WithAuthorization(PublishRejectComponent);
