import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";

export const hasContentChildren = (content) => {
  return (
    !!content?.parent?.id &&
    !MULTITENANT_CONSTANTS[TENANT].showOnlySons
  );
};

export const hasNoContentChildren = (content) => {
  return (
    (MULTITENANT_CONSTANTS[TENANT].showOnlySons || !content?.parent?.id)
  );
};
