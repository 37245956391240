import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { URL_LOGIN, URL_REGISTER } from "@components/Routes/Location";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";
import {
  SELECTAGE,
  NAMEUSER,
  AVATAR,
  CTITOR,
  CTITOR2,
  DATAUSER,
  CATEGORY,
  CONDITIONS,
  CONFIRMATION,
  FINISHED,
  ROL_KID,
  ROL_TEEN,
  ROL_ADULT,
} from "./PantallasRexistro";
import Avatar from "./Avatar";
import Category from "./Category";
import Conditions from "./Conditions";
import Confirm from "./Confirm";
import CTitor from "./CTitor";
import CTitor2 from "./CTitor2";
import DataUser from "./DataUser";
import NameUser from "./NameUser";
import SelectAge from "./SelectAge";
import "./RegisterV2.css";
import {
  TENANT,
  MULTITENANT_CONSTANTS,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";

import "../common/components/HeaderRegistrationComponent";
import HeaderRegistrationComponent from "../common/components/HeaderRegistrationComponent";
import { SIGNUP_MODE } from "../../../../app/src/Resources/Multitenant/tenantConstants";
import { SIGNUP_MODE_SIMPLE } from "./RegisterConstant";
import {
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import {
  getAvailableAvatarsService,
  getMenu,
  getUserInterestsService,
  logUserAnalyticService,
  validateEmailService,
  validateUsernameService,
  createUserService,
} from "../../../../app/src/services/services";
import {
  URL_BASE,
} from "../../../../app/src/services/servicesConstants";
import {
  PAGE_ID_KEY,
  PAGE_VIEW_ACTION,
  SIGN_UP_VALUE,
} from "../../../../app/src/analyticsConstants/analyticsConstants";
import { URL_HOME } from "../../../../app/src/Components/Routes/Location";
import { DEFAULT_AVATAR_ID, FORM_STEPS } from "../../../../app/src/Utils/Constants";
import { isCordovaAvailable } from "../../../../app/src/Utils/secureStorageUtil";
import { useTranslation } from "react-i18next";

const RegisterV2 = (props) => {
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const { i18n } = useTranslation();
  const baseUrl = URL_BASE;

  const [pantalla, setPantalla] = useState(
    SIGNUP_MODE === SIGNUP_MODE_SIMPLE ? DATAUSER : SELECTAGE
  );
  const [waitingForLoginResponse, setWaitingForLoginResponse] = useState(false);
  const [pantallasAnteriores, setPantallasAnteriores] = useState([]);
  const [cancelado, setCancelado] = useState(false);
  const [datosState, setDatosState] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [avatares, setAvatares] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [msgErroRexistro, setMsgErroRexistro] = useState(null);
  const [interfaceData, setInterfaceData] = useState(null);
  const [legalConditions, setLegalConditions] = useState(null);

  const convertToMd5 = (inputString) => {
    return CryptoJS.MD5(inputString).toString();
  };

  const tenantStyles = useSelector((state) => state.commonState.tenantStyles);
  let history = useHistory();

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const getMenuCallback = (response) => {
    turnOffLoading();
    const legalText = response?.legal?.text;

    setInterfaceData(response);
    setLegalConditions(legalText);
    setLanguages(response?.languages);
    setDatosState((prev) => ({ ...prev, language: response?.languages[0] }));
  };

  useEffect(() => {
    turnOnLoading();
    getMenu(getMenuCallback);
    logUserAnalyticService(
      TENANT,
      "anonymous",
      PAGE_VIEW_ACTION,
      PAGE_ID_KEY,
      SIGN_UP_VALUE,
      null,
      null
    );
  }, []);

  useEffect(() => {
    setMsgErroRexistro(false);
  }, [pantalla]);

  //Fluxo de pantallas
  const seguintePantalla = (rol) => {
    if (pantalla === CONFIRMATION) {
      props.changeFormStep(FORM_STEPS.LOGIN);
    }
    switch (pantalla) {
      default:
      case DATAUSER:
        setPantallasAnteriores([...pantallasAnteriores, DATAUSER]);
        setPantalla(CONDITIONS);
        break;
      case CONDITIONS:
        setPantallasAnteriores([...pantallasAnteriores, CONDITIONS]);
        setPantalla(CONFIRMATION);
        break;
      case CONFIRMATION:
        setPantallasAnteriores(...pantallasAnteriores, CONFIRMATION);
        setPantalla(FINISHED);
        break;
    }
  };

  const anteriorPantalla = () => {
    if (pantallasAnteriores.length === 0) {
      props.changeFormStep(FORM_STEPS.LOGIN);
      return;
    }
    setPantalla(pantallasAnteriores[pantallasAnteriores.length - 1]);
    setPantallasAnteriores(pantallasAnteriores.slice(0, -1));
  };

  if (!(initialized || isCordovaAvailable()) || !keycloak.clientId) {
    return <div className="loading" />;
  }

  if (keycloak && keycloak.authenticated) {
    keycloak.logout();
    return <div className="loading" />;
  }

  if (cancelado) {
    return <Redirect to={URL_HOME} />;
  }

  if (pantalla === FINISHED) {
    return <Redirect to={URL_HOME} />;
  }

  const ctrlSetFirstName = (e) => {
    if (e.trim !== "") {
      setDatosState((prev) => ({ ...prev, firstName: e }));
    }
  };

  const ctrlSetLastName = (e) => {
    if (e.trim !== "") {
      setDatosState((prev) => ({ ...prev, lastName: e }));
    }
  };

  const validateUsernameCallback = (response) => {
    if (response.data.exists === true) {
      setInvalidUsername(true);
    } else {
      const parsedResponse = JSON.parse(response.data.error_error.http_body);

      if (parsedResponse.exists === false) {
        setDatosState((prev) => ({ ...prev, username: response.username }));
      }
    }
  };

  const ctrlSetUsername = (e) => {
    setInvalidUsername(false);
    validateUsernameService(TENANT, e.toLowerCase(), validateUsernameCallback);
  };

  const validateEmailCallback = (response) => {
    if (response.data.exists === true) {
      setInvalidEmail(true);
    } else {
      const parsedResponse = JSON.parse(response.data.error_error.http_body);

      if (parsedResponse.exists === false) {
        setDatosState((prev) => ({ ...prev, email: response.email }));
      }
    }
  };

  const ctrlSetEmail = (e) => {
    setInvalidEmail(false);
    let re = /^\S+@\S+\.\S+$/;

    if (re.test(e)) {
      validateEmailService(TENANT, e.toLowerCase(), validateEmailCallback);
    } else {
      setInvalidEmail(true);
    }
  };

  const ctrlSetCredentials = (p) => {
    const validatePassword = (password) => {
      const validator =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/;
      return validator.test(password);
    };

    const isValid = validatePassword(p);

    if (isValid) {
      setDatosState((prev) => ({ ...prev, credentials: p }));
      setInvalidPassword(false);
    } else {
      setInvalidPassword(true);
    }
  };

  const ctrlSetBirthdate = (date) => {
    setDatosState((prev) => ({ ...prev, birthdate: date }));
  };

  const createUserCallback = (response) => {
    if (response.error_error) {
      setMsgErroRexistro(true);
    } else {
      setPantallasAnteriores((pantallasAnteriores) =>
        Array.from(new Set([...pantallasAnteriores, CONDITIONS]))
      );

      setPantalla(CONFIRMATION);
    }
  };

  const createUserErrorCallback = () => {
    setMsgErroRexistro(true);

    setTimeout(() => {
      history.push(URL_LOGIN);
    }, 3500);
  };

  const ctrlAcceptLegalConditions = (c) => {
    if (!waitingForLoginResponse) {
      setWaitingForLoginResponse(true);
      const bodyRequest = {
        username: datosState.username,
        credentials: datosState.credentials,
        firstName: datosState.firstName,
        lastName: datosState.lastName,
        email: datosState.email,
        avatar: DEFAULT_AVATAR_ID,
        birthdate: datosState.birthdate,
        acceptedLegalVersion: interfaceData?.legal?.version,
      };
  
      createUserService(
        TENANT,
        bodyRequest,
        createUserCallback,
        createUserErrorCallback
      );
    }
  };

  const validar = (password2) => {
    if (password2 === datosState.credentials) {
      setInvalidPassword(false);
    } else {
      setInvalidPassword(true);
      return;
    }

    // Username
    if (!datosState.username) {
      setInvalidUsername(true);
      return;
    } else {
      setInvalidUsername(false);
    }

    //Email
    if (!datosState.email && SIGNUP_MODE !== SIGNUP_MODE_SIMPLE) {
      setInvalidEmail(true);
      return;
    } else {
      setInvalidEmail(false);
    }

    seguintePantalla();
  };

  return (
    <div
      className={"Register " + (datosState?.rol ? datosState?.rol : "")}
      style={{
        "backgroundImage": "url(" + MULTITENANT_CONSTANTS[TENANT]?.backgroundRegister + ")",
      }}
    >
      <div className="App-container">
        <div className="Register-Container">
          <HeaderRegistrationComponent
            pageNumber={pantallasAnteriores.length}
            pantalla={pantalla}
            rol={datosState?.rol}
            language={i18n.language}
            languages={languages}
          />
          {pantalla === DATAUSER && (
            <DataUser
              datos={datosState}
              setDatosState={setDatosState}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
              ctrlSetFirstName={ctrlSetFirstName}
              ctrlSetLastName={ctrlSetLastName}
              ctrlSetUsername={ctrlSetUsername}
              ctrlSetEmail={ctrlSetEmail}
              ctrlSetCredentials={ctrlSetCredentials}
              invalidUsername={invalidUsername}
              invalidPassword={invalidPassword}
              invalidEmail={invalidEmail}
              followButtonDisabled={
                invalidEmail || invalidUsername || invalidPassword
              }
              validar={validar}
              guestLink={false}
              ctrlSetBirthdate={ctrlSetBirthdate}
            />
          )}
          {pantalla === CONDITIONS && (
            <Conditions
              legalConditions={legalConditions}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
              msgErroRexistro={msgErroRexistro}
              setMsgErroRexistro={setMsgErroRexistro}
              ctrlAcceptLegalConditions={ctrlAcceptLegalConditions}
              guestLink={false}
            />
          )}
          {pantalla === CONFIRMATION && (
            <Confirm
              datos={datosState}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
              guestLink={false}
              handleGoToLogin={() => {props.changeFormStep(FORM_STEPS.LOGIN)}}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterV2;
