import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Routes from "./Components/Routes/Routes";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { Provider } from "react-redux";
import { store } from "@app/Utils/utils";
import keycloak from "./Utils/keycloak";
import { KeycloakProvider } from "@react-keycloak/web";
import { isCordovaAvailable } from "./Utils/secureStorageUtil";

registerLocale("es", es);
setDefaultLocale("es");

const App = () => {
    const cordovaInitConfig = {
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin
      }
      const initConfig = {
        checkLoginIframe: false,
      
      };
      
    return (
        <KeycloakProvider keycloak={keycloak} initConfig={isCordovaAvailable() ? cordovaInitConfig : initConfig}>
            <Provider store={store}>
                <React.StrictMode>
                    <Routes className="loader" />
                </React.StrictMode>
            </Provider>
        </KeycloakProvider>
    );
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
