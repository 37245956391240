import React from "react";

import PetisgoLoader from "./petisgo/PetisgoLoader";
import AndromedaLoader from "./andromeda/AndromedaLoader";

import defaultFavicon from "./default/Resources/favicon.ico";
import petisgoFavicon from "./petisgo/Resources/favicon.png";
import andromedaFavicon from "./andromeda/Resources/favicon.png";
import casiopeaFavicon from "./casiopea/Resources/favicon.ico";
import tiiviiFavicon from "./tiivii/Resources/favicon.ico";
import bolerusFavicon from "./bolerus/Resources/favicon.ico";
import dracoFavicon from "./draco/Resources/favicon.ico";
import eudoraFavicon from "./eudora/Resources/favicon.ico";
import geminiFavicon from "./gemini/Resources/favicon.ico";
import felisFavicon from "./felis/Resources/favicon.ico";
import lupusFavicon from "./lupus/Resources/favicon.ico";
import indusFavicon from "./indus/Resources/favicon.ico";

import petisgoBackground from "./petisgo/Resources/background_register.jpg";
import andromedaBackground from "./andromeda/Resources/background_register.jpg";
import defaultBackground from "./default/Resources/background_register.jpg";
import tiiviitBackground from "./tiivii/Resources/background_register.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";
import bolerusBackground from "./bolerus/Resources/background_register.jpg";
import dracoBackground from "./draco/Resources/background_register.png";
import eudoraBackground from "./eudora/Resources/background_register.jpg";
import casiopeaBackground from "./casiopea/Resources/background_register.jpg";
import geminiBackground from "./gemini/Resources/fondo-login-dtm.png";
import felisBackground from "./felis/Resources/fondo-login-dtm.JPG";
import lupusBackground from "./lupus/Resources/background_register.jpg";
import indusBackground from "./indus/Resources/background_register.png";

/*import { coreBuild } from "../../../core-framework";*/
import {
  SIGNUP_MODE_SIMPLE,
} from "../../../../user/src/Pages/Register/RegisterConstant";

//Necesario ya que el core ahora está devolviendo sólo el cumpleaños.
const registerMode = () => {
  return SIGNUP_MODE_SIMPLE
  /*if (!!coreBuild.coreConfig.signup.mode.id) {
    return coreBuild.coreConfig.signup.mode.id;
  } else {
    return coreBuild.coreConfig.signup.mode.defaultBirthday === DEFAULT_SIMPLE_BIRTH_DATE
      ? SIGNUP_MODE_SIMPLE
      : SIGNUP_MODE_BY_AGE;
  }*/
};

export const SIGNUP_MODE = registerMode();

export const TENANT_TIIVII = "tiivii";
export const TENANT_TIIVII_SERVER = "ott_tiivii";
export const TENANT_ANDROMEDA = "andromeda";
export const TENANT_PETISGO = "petisgo";
export const TENANT_DEFAULT = "default";
export const TENANT_CASIOPEA = "casiopea";
export const TENANT_BOLERUS = "bolerus";
export const TENANT_DRACO = "draco";
export const TENANT_EUDORA = "eudora";
export const TENANT_GEMINI = "gemini";
export const TENANT_FELIS = "felis";
export const TENANT_LUPUS = "lupus";
export const TENANT_INDUS = "indus";

export const TENANT_REGISTERED = [
  TENANT_TIIVII,
  TENANT_ANDROMEDA,
  TENANT_PETISGO,
  TENANT_CASIOPEA,
  TENANT_BOLERUS,
  TENANT_DRACO,
  TENANT_EUDORA,
  TENANT_GEMINI,
  TENANT_FELIS,
  TENANT_LUPUS,
  TENANT_INDUS,
  TENANT_TIIVII_SERVER
];

/*export const TENANT = TENANT_REGISTRED.find((item) => item === coreBuild.envConfig.tenant)
  ? coreBuild.envConfig.tenant
  : TENANT_DEFAULT;*/

export const TENANT = TENANT_REGISTERED.find((item) => item === process.env.REACT_APP_CORE_TENANT)
  ? process.env.REACT_APP_CORE_TENANT
  : TENANT_DEFAULT;
export const ENVIRONMENT = process.env.REACT_APP_CORE_ENV;
export const ENVIRONMENT_PRE = "pre";
export const ENVIRONMENT_PRO = "pro";

export const KC_REALM = process.env.REACT_APP_REALM
export const KC_BASE_URI = process.env.REACT_APP_KC_BASE_URI
export const KC_CLIENT_ID = process.env.REACT_APP_CLIENT_ID


const PETISGO = "PetisGo Creator";
const CREATOR = "Creator";
const DRACO_CREATOR = "Play Castelló";
const STUDIO = "Studio";

const iconSettings = (
  <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M368 128h80M64 128h240M368 384h80M64 384h240M208 256h240M64 256h80"
    />
    <circle
      cx="336"
      cy="128"
      r="32"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <circle
      cx="176"
      cy="256"
      r="32"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
    <circle
      cx="336"
      cy="384"
      r="32"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
  </svg>
);

const iconLogout = (
  <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512">
    <path
      d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
    />
  </svg>
);

const baseTiiviiConfig = {
  pageTitle: "tiivii. Plataforma audiovisual del deporte gallego",
  description: "Bienvenid@ a tivii.gal , la plataforma audiovisual del deporte gallego. Fútbol, fútbol sala, baloncesto, balonmano, hockey patines, voleibol, … en directo y en diferido, en cualquier dispositivo.",
  keywords: ["deporte gallego", "Fútbol", "fútbol sala", "baloncesto", "balonmano", "hockey patines", "voleibol"],
  canonical: "https://tiivii.gal",
  domain: "https://tiivii.gal/",
  defaultLocale: "gl",
  spinner: <CircularProgress/>,
  favicon: tiiviiFavicon,
  backgroundRegister: tiiviitBackground,
  momentLocale: "es",
  showVideoDetails: false,
  showVideoTags: false,
  supportEmail: false,
  studioSectionName: CREATOR,
  showComments: true,
  showAllIcons: false,
  showScore: false,
  showSubtitle: false,
  showLoginButton: true,
  gamification: false,
  urlFaqs: {"es": "https://info.tiivii.gal/#preguntas-frecuentes", "en": "https://info.tiivii.gal/en/#faq", "gl": "https://info.tiivii.gal/gl/#preguntas-frecuentes"},
  urlTermsAndConditions: {"es": "https://info.tiivii.gal/terminos-y-condiciones", "en": "https://info.tiivii.gal/en/terms-conditions/", "gl": "https://info.tiivii.gal/gl/termos-e-condicions/"},
  urlPrivacyStatement: {"es": "https://info.tiivii.gal/politica-privacidad", "en": "https://info.tiivii.gal/en/privacy-policy/", "gl": "https://info.tiivii.gal/gl/politica-de-privacidade/"},
  classHeight: "",
  defaultBirthday: "1800-01-01",
  secret: "cinfo-petisgo-api-secret",
  bannerHighlightedHeightClassName: "PlaylistHighlight",
  showOnlySons: false,
  classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
  showSlideHighlightIndicators: true,
  configurationSectionImage: "",
  logoutSectionImage: "",
  menuClassLogout: "LogoutIcon",
  showAuthor: false,
  showCategory: false,
  showLoginButtonInMobile: true,
  showNotifications: true,
  showPublishDate: false,
  showRating: false,
  showBackToSeason: true,
  showRegistrationText: false,
  showReportContentOption: false,
  showOnBoarding: false,
  showUserInterests: true,
  showSubscriptions: false,
  contentDetailsVersion: 2,
  discoverContentsVersion: 2,
  showViewButton: true,
  showShareButtons: true,
  /*cookies: se meten desde el index.html*/
  sticky: true,
  maxGuestAgeRating: null,
  /*firebaseApiKey: {
    pre: "AIzaSyC0J0_ISR63C6i1YbBNyw7o-Pc4RgvFt7E",
    pro: "AIzaSyAuTFu-pVO3-hNTLWHYTuWqWN6AmqL0F48",
  },*/
  domainUriPrefix: "",
  link: "",
  androidInfo: {
    androidPackageName: "",
  },
  iosInfo: {
    iosBundleId: "",
    iosIpadBundleId: "",
    iosAppStoreId: "",
  },
  studioUrl: {
    dev: undefined,
    pre: undefined,
    pro: undefined,
  },
  playMarathon:  true,
  seasonClassification: true,
}


export const MULTITENANT_CONSTANTS = {
  [TENANT_PETISGO]: {
    pageTitle: "PetisGO",
    defaultLocale: "gl",
    spinner: <PetisgoLoader />,
    favicon: petisgoFavicon,
    backgroundRegister: petisgoBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    supportEmail: "soporte@petisgo.gal",
    studioSectionName: PETISGO,
    showComments: true,
    showAllIcons: true,
    showScore: true,
    showSubtitle: false,
    showLoginButton: true,
    gamification: true,
    urlFaqs: "",
    classHeight: "",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-petisgo-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItem",
    showSlideHighlightIndicators: true,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: true,
    showCategory: true,
    showLoginButtonInMobile: true,
    showNotifications: true,
    showPublishDate: true,
    showRating: true,
    showBackToSeason: true,
    showRegistrationText: true,
    showReportContentOption: true,
    showOnBoarding: true,
    showUserInterests: true,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    showViewButton: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_PETISGO,
    playMarathon:  true,
    seasonClassification: true,
    maxGuestAgeRating: 0,
    /*firebaseApiKey: {
      dev: "AIzaSyC-K9CXRTwMPA9yJXEgwYx5Jj21szF0uuQ",
      pre: "AIzaSyAJjf61urjxoqDy7DVhfir4iby723rDz7E",
      pro: "AIzaSyC62_CukscwtmWVJYxAwQ6cUd6NFpld_eM",
    },*/
    domainUriPrefix: "",
    link: "",
    androidInfo: {
      androidPackageName: "",
    },
    iosInfo: {
      iosBundleId: "",
      iosIpadBundleId: "",
      iosAppStoreId: "",
    },
    studioUrl: {
      dev: "https://petisgoweb-devt.tiivii.com/adminui",
      pre: "https://petisgo-pre.tiivii.com/studio",
      pro: "https://petisgo.tiivii.com/studio",
    },
  },
  [TENANT_DEFAULT]: {
    pageTitle: "Tiivii",
    defaultLocale: "en",
    spinner: <CircularProgress />,
    favicon: defaultFavicon,
    backgroundRegister: defaultBackground,
    momentLocale: "es",
    showVideoDetails: false,
    showVideoTags: true,
    supportEmail: false,
    studioSectionName: CREATOR,
    showComments: true,
    showAllIcons: false,
    showScore: true,
    showSubtitle: false,
    showLoginButton: true,
    gamification: false,
    urlFaqs: "",
    classHeight: "",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-petisgo-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    showControlsHighlightedComponent: true,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showNotifications: true,
    showPublishDate: true,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    showViewButton: true,
    showShareButtons: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: ENVIRONMENT === ENVIRONMENT_PRE ? TENANT_TIIVII : TENANT_TIIVII_SERVER,
    playMarathon:  true,
    seasonClassification: true,
    maxGuestAgeRating: null,
    /*firebaseApiKey: {
      pre: "AIzaSyC0J0_ISR63C6i1YbBNyw7o-Pc4RgvFt7E",
      pro: "AIzaSyAuTFu-pVO3-hNTLWHYTuWqWN6AmqL0F48",
    },*/
    domainUriPrefix: "",
    link: "",
    androidInfo: {
      androidPackageName: "",
    },
    iosInfo: {
      iosBundleId: "",
      iosIpadBundleId: "",
      iosAppStoreId: "",
    },
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
  [TENANT_ANDROMEDA]: {
    pageTitle: "Oh! Jazz",
    defaultLocale: "en",
    domain: "https://watch.ohjazz.tv",
    spinner: <AndromedaLoader />,
    favicon: andromedaFavicon,
    backgroundRegister: andromedaBackground,
    registerUrl: "https://register.ohjazz.tv/",
    tutorialUrl: "https://www.ohjazz.tv/tutorials",
    momentLocale: "en",
    showVideoDetails: false,
    showVideoTags: false,
    supportEmail: false,
    studioSectionName: PETISGO,
    showComments: false,
    showAllIcons: false,
    showScore: false,
    showSubtitle: false,
    showLoginButton: true,
    gamification: false,
    urlFaqs: "",
    classHeight: "ImgHeight",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-andromeda-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: true,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    showControlsHighlightedComponent: false,
    configurationSectionImage: iconSettings,
    logoutSectionImage: iconLogout,
    menuClassLogout: "",
    contentDetailsVersion: 2,
    showCalendarButton: true,
    showAuthor: false,
    showCategory: false,
    showLoginButtonInMobile: false,
    showNotifications: false,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: false,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: false,
    showSubscriptions: true,
    showDeleteUserButton: false,
    discoverContentsVersion: 2,
    showViewButton: true,
    showProfileLegalText: 'true',
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_ANDROMEDA,
    playMarathon:  true,
    seasonClassification: true,
    maxGuestAgeRating: null,
    /*firebaseApiKey: {
      pre: "AIzaSyCeLj_QvmJpqTrif7lvujUcMsvHivESIpg",
      pro: "AIzaSyAb6VX8CSJToiMgYUcdtx-d7gkdyQa7F_M",
    },*/
    domainUriPrefix: "https://ohjazz.page.link",
    link: "https://watch.ohjazz.tv",
    androidInfo: {
      androidPackageName: "es.cinfo.andromeda",
    },
    iosInfo: {
      iosBundleId: "com.ohjazztv.ohjazz",
      iosIpadBundleId: "com.ohjazztv.ohjazz",
      iosAppStoreId: "1618718051",
    },
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
  [TENANT_TIIVII]: {
  ...baseTiiviiConfig,
    showControlsHighlightedComponent: true,
    tenantServer: TENANT_TIIVII,
  },
  [TENANT_TIIVII_SERVER]: {
    ...baseTiiviiConfig,
    tenantServer: TENANT_TIIVII_SERVER,
    showShareButtons: true,
    playMarathon:  true,
    seasonClassification: true,

  },
  [TENANT_CASIOPEA]: {
    pageTitle: "Teatre Principal - Teatro",
    defaultLocale: "ca",
    spinner: <CircularProgress />,
    favicon: casiopeaFavicon,
    backgroundRegister: casiopeaBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    supportEmail: false,
    studioSectionName: CREATOR,
    showComments: false,
    showAllIcons: false,
    showScore: false,
    showSubtitle: true,
    showLoginButton: true,
    showNotifications: false,
    gamification: false,
    urlFaqs: "",
    classHeight: "ImgHeight",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-casiopea-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlightBig",
    showOnlySons: true,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    showControlsHighlightedComponent: false,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: false,
    showLoginButtonInMobile: true,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: false,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    showViewButton: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_CASIOPEA,
    playMarathon:  true,
    seasonClassification: true,
    maxGuestAgeRating: null,
    /*firebaseApiKey: {
      pro: "AIzaSyD9KGXdVoK-SN8QAH1x0h7qQJeriQ0M0Ug",
    },*/
    domainUriPrefix: "",
    link: "",
    androidInfo: {
      androidPackageName: "",
    },
    iosInfo: {
      iosBundleId: "",
      iosIpadBundleId: "",
      iosAppStoreId: "",
    },
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
  [TENANT_BOLERUS]: {
    pageTitle: "Lalin Infinito OTT",
    defaultLocale: "gl",
    spinner: <CircularProgress />,
    favicon: bolerusFavicon,
    backgroundRegister: bolerusBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    supportEmail: false,
    studioSectionName: CREATOR,
    showComments: true,
    showAllIcons: false,
    showScore: false,
    showSubtitle: false,
    showLoginButton: true,
    showNotifications: true,
    gamification: false,
    urlFaqs: "",
    classHeight: "",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-bolerus-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    showControlsHighlightedComponent: false,
    configurationSectionImage: iconSettings,
    logoutSectionImage: iconLogout,
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: true,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showPreviews: false,
    showUserInterests: true,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    showViewButton: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_BOLERUS,
    playMarathon:  true,
    seasonClassification: true,
    maxGuestAgeRating: null,
    /*firebaseApiKey: {
      pro: "AIzaSyBl13EsvgG0yBAya6KgF2RfbQQlvnJR0z8",
    },*/
    domainUriPrefix: "",
    link: "",
    androidInfo: {
      androidPackageName: "",
    },
    iosInfo: {
      iosBundleId: "",
      iosIpadBundleId: "",
      iosAppStoreId: "",
    },
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
  [TENANT_DRACO]: {
    pageTitle: "Irun",
    defaultLocale: "eu",
    spinner: <CircularProgress />,
    favicon: dracoFavicon,
    backgroundRegister: dracoBackground,
    momentLocale: "es",
    showVideoDetails: false,
    showVideoTags: true,
    supportEmail: false,
    studioSectionName: DRACO_CREATOR,
    showComments: false,
    showAllIcons: true,
    showScore: false,
    showSubtitle: false,
    showLoginButton: false,
    showNotifications: true,
    gamification: false,
    urlFaqs: "",
    urlTermsAndConditions: "https://irun.market/index.php?dispatch=pages.view&page_id=44",
    urlPrivacyStatement: " https://irun.market/index.php?dispatch=pages.view&page_id=3",
    classHeight: "",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-draco-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    showViewButton: true,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_DRACO,
    playMarathon:  true,
    seasonClassification: true,
    maxGuestAgeRating: null,
    /*firebaseApiKey: {
      pro: "AIzaSyCRtm8lwkPrwz_Ym-qMsKfwZ08KaLTeuqA",
    },*/
    domainUriPrefix: "",
    link: "",
    androidInfo: {
      androidPackageName: "",
    },
    iosInfo: {
      iosBundleId: "",
      iosIpadBundleId: "",
      iosAppStoreId: "",
    },
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
  [TENANT_INDUS]: {
    pageTitle: "Puente Genil",
    defaultLocale: "es",
    spinner: <CircularProgress />,
    favicon: indusFavicon,
    backgroundRegister: indusBackground,
    momentLocale: "es",
    showVideoDetails: false,
    showVideoTags: true,
    supportEmail: false,
    studioSectionName: DRACO_CREATOR,
    showComments: false,
    showAllIcons: true,
    showScore: false,
    showSubtitle: false,
    showLoginButton: false,
    showNotifications: true,
    gamification: false,
    urlFaqs: "",
    urlTermsAndConditions: "",
    urlPrivacyStatement: "",
    classHeight: "",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-draco-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItem",
    showSlideHighlightIndicators: true,
    showControlsHighlightedComponent: false,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_INDUS,
    playMarathon:  true,
    seasonClassification: true,
    maxGuestAgeRating: null,
    /*firebaseApiKey: {
      pro: "AIzaSyCRtm8lwkPrwz_Ym-qMsKfwZ08KaLTeuqA",
    },*/
    domainUriPrefix: "",
    link: "",
    androidInfo: {
      androidPackageName: "",
    },
    iosInfo: {
      iosBundleId: "",
      iosIpadBundleId: "",
      iosAppStoreId: "",
    },
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
  [TENANT_EUDORA]: {
    pageTitle: "Arteixo Conecta",
    defaultLocale: "gl",
    spinner: <CircularProgress />,
    favicon: eudoraFavicon,
    backgroundRegister: eudoraBackground,
    momentLocale: "es",
    showVideoDetails: true,
    showVideoTags: true,
    supportEmail: false,
    studioSectionName: CREATOR,
    showComments: true,
    showAllIcons: false,
    showScore: false,
    showSubtitle: false,
    showLoginButton: true,
    showNotifications: true,
    gamification: false,
    urlFaqs: "",
    classHeight: "",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-eudora-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    showControlsHighlightedComponent: false,
    configurationSectionImage: iconSettings,
    logoutSectionImage: iconLogout,
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: true,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    showViewButton: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_EUDORA,
    playMarathon:  true,
    seasonClassification: true,
    maxGuestAgeRating: null,
    /*firebaseApiKey: {
      pro: "AIzaSyA-SvVvj--WTdBH-PqQjZ0v_CMiDaoGHBI",
    },*/
    domainUriPrefix: "",
    link: "",
    androidInfo: {
      androidPackageName: "",
    },
    iosInfo: {
      iosBundleId: "",
      iosIpadBundleId: "",
      iosAppStoreId: "",
    },
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
  [TENANT_GEMINI]: {
    pageTitle: "move.gal",
    defaultLocale: "gl",
    spinner: <CircularProgress />,
    favicon: geminiFavicon,
    backgroundRegister: geminiBackground,
    momentLocale: "es",
    showVideoDetails: false,
    showVideoTags: false,
    supportEmail: false,
    studioSectionName: CREATOR,
    showComments: true,
    showAllIcons: true,
    showScore: false,
    showSubtitle: false,
    showLoginButton: true,
    showNotifications: true,
    gamification: false,
    urlFaqs: "https://landings.tiivii.com/geminifaqs/",
    classHeight: "",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-gemini-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItem",
    showSlideHighlightIndicators: true,
    showControlsHighlightedComponent: false,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: true,
    showOnBoarding: false,
    showUserInterests: true,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    showViewButton: false,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_GEMINI,
    playMarathon:  true,
    seasonClassification: false,
    maxGuestAgeRating: null,
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
  [TENANT_FELIS]: {
    pageTitle: "Redondela Aberta",
    defaultLocale: "gl",
    spinner: <CircularProgress />,
    favicon: felisFavicon,
    backgroundRegister: felisBackground,
    momentLocale: "es",
    showVideoDetails: false,
    showVideoTags: true,
    supportEmail: false,
    studioSectionName: CREATOR,
    showComments: false,
    showAllIcons: false,
    showScore: false,
    showSubtitle: false,
    showLoginButton: true,
    showNotifications: true,
    gamification: false,
    urlFaqs: "",
    classHeight: "",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-felis-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItemByTenant",
    showSlideHighlightIndicators: true,
    showControlsHighlightedComponent: false,
    configurationSectionImage: iconSettings,
    logoutSectionImage: iconLogout,
    menuClassLogout: "LogoutIcon",
    showAuthor: false,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: false,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: false,
    showOnBoarding: false,
    showUserInterests: true,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    showViewButton: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_FELIS,
    playMarathon:  true,
    seasonClassification: true,
    maxGuestAgeRating: null,
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
  [TENANT_LUPUS]: {
    pageTitle: "Hubrural",
    description: "Programa de formación desarrollado por la Diputación de Pontevedra en los concellos de A Illa de Arousa, As Neves, Forcarei y Moraña, enfocado en abordar los desafíos del cambio demográfico, la brecha digital y la intergeneracional en entornos rurales. Este proyecto busca promover la participación de las personas mayores en la sociedad y mejorar su autonomía y calidad de vida mediante el uso de nuevas tecnologías. Las actividades formativas se llevarán a cabo en aulas presenciales, equipadas con tecnología avanzada y una innovadora plataforma de formación.",
    keywords: ["Programa de formación Pontevedra", "Diputación de Pontevedra", "Cambio demográfico rural", "Brecha digital", "Participación personas mayores", "Autonomía personas mayores", "Calidad de vida rural", "Formación en nuevas tecnologías", "Telemedicina y teleasistencia", "Concellos A Illa de Arousa", "Concellos As Neves", "Concellos Forcarei", "Concellos Moraña", "Aulas formativas rurales"],
    canonical: "https://hubrural.gal/home",
    defaultLocale: "gl",
    spinner: <CircularProgress />,
    favicon: lupusFavicon,
    backgroundRegister: lupusBackground,
    momentLocale: "es",
    showVideoDetails: false,
    showVideoTags: true,
    supportEmail: false,
    studioSectionName: STUDIO,
    showComments: true,
    showAllIcons: true,
    showScore: false,
    showSubtitle: false,
    showLoginButton: true,
    showNotifications: false,
    gamification: false,
    urlFaqs: "https://hubrural.gal/landing/preguntas/",
    classHeight: "",
    defaultBirthday: "1800-01-01",
    secret: "cinfo-lupus-api-secret",
    bannerHighlightedHeightClassName: "PlaylistHighlight",
    showOnlySons: false,
    classPlaylistHighlightItem: "PlaylistHighlightItem",
    showSlideHighlightIndicators: true,
    showControlsHighlightedComponent: false,
    configurationSectionImage: "",
    logoutSectionImage: "",
    menuClassLogout: "LogoutIcon",
    showAuthor: true,
    showCategory: true,
    showLoginButtonInMobile: true,
    showPublishDate: true,
    showRating: false,
    showBackToSeason: true,
    showRegistrationText: false,
    showReportContentOption: true,
    showOnBoarding: false,
    showUserInterests: false,
    showSubscriptions: false,
    discoverContentsVersion: 2,
    showViewButton: true,
    /*cookies: se meten desde el index.html*/
    sticky: true,
    tenantServer: TENANT_LUPUS,
    playMarathon:  false,
    seasonClassification: false,
    maxGuestAgeRating: null,
    contentDetailsVersion: 2,
    studioUrl: {
      dev: undefined,
      pre: undefined,
      pro: undefined,
    },
  },
};
